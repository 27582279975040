import React from "react"
import ReactImageMagnify from "react-image-magnify"

import "./ClassificationPaintings.css"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

const ClassificationPaintings = (props) => {
  return (
    <div className="painting_wrapper">
      <div
        className="painting_container"
        onMouseEnter={props.showLoader}
        onMouseLeave={props.hideLoader}
        onLoad={props.onLoad}
      >
        <div className="loader_container">
          <div
            className={props.loaderVisible ? "loader" : "loader hidden"}
          ></div>
        </div>
        <ReactImageMagnify
          {...{
            smallImage: {
              alt: "dw",
              src: props.smallPortraitSrc,
              isFluidWidth: true,
              width: 2600,
              height: 1463,
            },
            enlargedImagePosition: "over",
            largeImage: {
              src: props.largePortraitSrc,
              width: 7800,
              height: 4389,
              onLoad: props.onLoad,
            },
            hoverDelayInMs: 0,
          }}
        />
      </div>
    </div>
  )
}

export default ClassificationPaintings
