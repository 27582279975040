import React, { Component } from "react"
import { Link } from "react-router-dom"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"
// import PrismaZoom from "react-prismazoom"
import { MapInteractionCSS } from "react-map-interaction"

import "../artwork/ai/Ai.css"

class Tyra extends Component {
  state = {
    loading: false,
    positionY: "",
    desktop: true,
    src:
      "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_aa_zoom.png",
  }
  componentDidMount() {
    document.title = "Daniel Wallace | Tyra"

    if (window.innerWidth > 2300) {
      this.setState({
        positionY: -450,
      })
    } else {
      this.setState({
        positionY: -700,
      })
    }
    // console.log(window.innerWidth, window.innerHeight, this.state.desktop)
    if (window.innerWidth < 400) {
      this.setState({ desktop: false })
    } else {
      this.setState({ desktop: true })
    }
    window.scrollTo(0, 0)
    this.props.hideNavBar()
    setTimeout(() => {
      this.setState({ loading: true })
    }, 400)
  }

  componentWillUnmount() {
    this.props.hideNavBar()
  }
  render() {
    return (
      <div
        className={
          this.state.loading
            ? "individual_ai_page"
            : "individual_ai_page hidden"
        }
      >
        <TransformWrapper
          options={{ minScale: 0.2, maxScale: 9 }}
          pan={{
            velocityEqualToMove: true,
            velocitySensitivity: 0.5,
          }}
          scale={1}
          positionY={this.state.positionY}
        >
          {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
            <React.Fragment>
              <div className="arrow_wrapper">
                <Link to={this.props.to} style={{ textDecoration: "none" }}>
                  <div className="arrow_container">
                    <i className="fas fa-chevron-left"></i>
                  </div>
                </Link>
              </div>
              <div className="magnifiers">
                <button onClick={zoomIn}>+</button>
                <button onClick={zoomOut}>-</button>
              </div>
              <TransformComponent>
                <img className="full_ai_img" src={this.state.src} alt="tyra" />
              </TransformComponent>
            </React.Fragment>
          )}
        </TransformWrapper>
        {/* <div className="magnifiers">
          <button onClick={this.getZoom}>+</button>
          <button onClick={this.zoomOut}>-</button>
        </div>
        <PrismaZoom minZoom={0.2}>
          <img src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_aa_zoom.png" />
        </PrismaZoom> */}
        {/* <div className="arrow_wrapper">
          <Link to="/work/decoded" style={{ textDecoration: "none" }}>
            <div className="arrow_container">
              <i className="fas fa-chevron-left"></i>
            </div>
          </Link>
        </div>
        <MapInteractionCSS
          showControls={true}
          // defaultValue={{ scale: 1, translation: { x: -240, y: -435 } }}
          defaultValue={{ scale: 1, translation: { x: 0, y: -200 } }}
          minScale={0.3}
          btnClass={"btns"}
        >
          <div class="humanoid_img_container">
            <img
              className="humanoid"
              src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_ty_zm_6.png"
            />
          </div>
        </MapInteractionCSS> */}
      </div>
    )
  }
}

export default Tyra
