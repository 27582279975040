// import React, { Component } from "react"
// import { Link } from "react-router-dom"
// import { MapInteractionCSS } from "react-map-interaction"

// import "./artwork/ai/Ai.css"

// class TyraMobile extends Component {
//   state = { loading: false }
//   componentDidMount() {
//     console.log(window.innerWidth, window.innerHeight, this.state.desktop)
//     if (window.innerWidth < 400) {
//       this.setState({ desktop: false })
//     } else {
//       this.setState({ desktop: true })
//     }
//     window.scrollTo(0, 0)
//     this.props.hideNavBar()
//     setTimeout(() => {
//       this.setState({ loading: true })
//     }, 200)
//   }
//   componentWillUnmount() {
//     this.props.hideNavBar()
//   }
//   render() {
//     console.log(this.props)
//     return (
//       <div
//         className={
//           this.state.loading
//             ? "individual_ai_page"
//             : "individual_ai_page hidden"
//         }
//       >
//         <div className="arrow_wrapper">
//           <Link to="/work/decoded" style={{ textDecoration: "none" }}>
//             <div className="arrow_container">
//               <i className="fas fa-chevron-left"></i>
//             </div>
//           </Link>
//         </div>
//         <MapInteractionCSS
//           showControls={true}
//           defaultValue={{
//             scale: 0.9,
//             translation: { x: -400, y: -200 },
//             // translation: { x: -680, y: -200 },
//           }}
//           minScale={0.3}
//           btnClass={"btns"}
//         >
//           <img
//             className="humanoid_mobile"
//             src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_aa_1900.png"
//           />
//         </MapInteractionCSS>
//       </div>
//     )
//   }
// }

// export default TyraMobile

import React, { Component } from "react"
import { Link } from "react-router-dom"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"

import "../artwork/ai/Ai"

class TyraMobile extends Component {
  state = { loading: false, x: 0, y: 0, desktop: true }
  componentDidMount() {
    if (window.innerWidth < 400) {
      this.setState({ desktop: false })
    } else {
      this.setState({ desktop: true })
    }
    window.scrollTo(0, 0)
    this.props.hideNavBar()
    setTimeout(() => {
      this.setState({ loading: true })
    }, 400)
  }
  componentWillUnmount() {
    this.props.hideNavBar()
  }
  render() {
    return (
      <div
        className={
          this.state.loading
            ? "individual_ai_page"
            : "individual_ai_page hidden"
        }
      >
        <TransformWrapper
          options={{ minScale: 0.2, maxScale: 9 }}
          pan={{ velocityEqualToMove: true, velocitySensitivity: 0.5 }}
          scale={0.85}
          positionX={150}
          positionY={-190}
        >
          {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
            <React.Fragment>
              <div className="arrow_wrapper">
                <Link to={this.props.to} style={{ textDecoration: "none" }}>
                  <div className="arrow_container">
                    <i className="fas fa-chevron-left"></i>
                  </div>
                </Link>
              </div>
              <div className="magnifiers">
                <button onClick={zoomIn}>+</button>
                <button onClick={zoomOut}>-</button>
              </div>
              <TransformComponent>
                <img
                  className="full_ai_img"
                  src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_aa_1900.png"
                  alt="test"
                />
              </TransformComponent>
            </React.Fragment>
          )}
        </TransformWrapper>
      </div>
    )
  }
}

export default TyraMobile
