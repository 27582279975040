import React, { Component } from "react"
import Portrait from "../artwork/portrait/Portrait"
import Diptych from "../artwork/portrait/diptych/Diptych"
import NewsPopup from "../../components/pop_up/news_popup/NewsPopup"
import { Link } from "react-router-dom"
// import Portrait from "../artwork/portrait/Portrait"

import ArtworkList from "../../components/artwork_list/ArtworkList"

import "./Decoded.css"
import Tyra from "../full_page_ai/Tyra"

export default class Videos extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      mobile: true,
      isPopupVisible: false,
      portraitWidth: 600,
      portraitHeight: 600,
      magnifiedWidth: 1800,
      magnifiedHeight: 1800,
      enlargedImagePosition: "beside",
    }
  }

  componentDidMount() {
    document.title = "Daniel Wallace | Decoded"

    setTimeout(() => {
      this.setState({ loading: true })
    }, 300)

    this.setState({ isPopupVisible: false })
    if (window.innerWidth < 500) {
      this.setState({ mobile: true })
    } else {
      this.setState({ mobile: false })
    }
  }
  togglePopup = () => {
    this.setState({ isPopupVisible: !this.state.isPopupVisible })
    this.props.setNavColorToGrey()
  }

  render() {
    return (
      <div
        className={
          this.state.loading ? "decoded_page fading-in" : "decoded_page"
        }
      >
        <NewsPopup
          isPopupVisible={this.state.isPopupVisible}
          togglePopup={this.togglePopup}
          setNavColorToGrey={this.props.setNavColorToGrey}
        />
        {/* <ArtworkList
          artworks={this.state.artworks}
          artworkListClass="artwork_list"
        /> */}
        <div className="decoded_description">
          <p>decoded</p>
          <div className="decoded_description-text">
            These portraits are from Wallace's series “Decoded”, which Wallace
            began producing while studying under the French photographer
            Brigitte Lacombe. Some of these portraits are of humans (each
            captured by Wallace during real-world portrait sittings at Wallace’s
            studio) and others are of figures who do not exist—digitial
            humanoids created by Wallace using a combination of generative
            artificial intelligence algorithms and digital painting.
          </div>
        </div>
        <div className="zoom_instructions_wrapper">
          <div className="zoom_instructions desktop">
            mouse over portraits to see details
          </div>
          <div className="zoom_instructions mobile">
            press and hold over portraits to see details
          </div>
        </div>
        <div className="portrait_diptych_wrapper">
          <Portrait
            smallPortraitSrc="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_aa.jpg"
            largePortraitSrc="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_aa_hi.png"
            portraitWidth={this.state.portraitWidth}
            portraitHeight={this.state.portraitHeight}
            magnifiedWidth={this.state.magnifiedWidth}
            magnifiedHeight={this.state.magnifiedHeight}
            status="ai"
            name="Tyra"
          />
          <div className="portrait-diptych_text_wrapper">
            <div className="portrait-diptych_text">
              <p>
                Each of the portraits in this series are imprinted with key
                excerpts from the ‘code’ with which the figures in the portraits
                are made: In the case of a figure created using generative
                artificial intelligence, you will see a key part of the
                algorithm involved in generating her overall form and
                composition; in the case of a human figure, you will see an
                excerpt from a key section of the DNA involved in ‘generating’
                her biologically.
              </p>
              <p>
                In these portraits Wallace engages the ancient aesthetic theme
                of an outward Siren beauty that belies a disturbing and sinister
                reality—an apt metaphor for modern technology itself.
              </p>
            </div>
          </div>
          {/* <Portrait
            smallPortraitSrc="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_j.jpg"
            largePortraitSrc="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_j_hi.png"
            portraitWidth={this.state.portraitWidth}
            portraitHeight={this.state.portraitHeight}
            magnifiedWidth={this.state.magnifiedWidth}
            magnifiedHeight={this.state.magnifiedHeight}
            status="human"
            year="2020"
          />
          <div className="portrait-diptych_text_wrapper">
            <div className="portrait-diptych_text">
              In these portraits Wallace engages the ancient aesthetic theme of
              an outward Siren beauty that belies a disturbing and sinister
              reality—an apt metaphor for modern technology itself.
            </div>
          </div> */}
          <Diptych
            smallPortraitSrcLeft="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_d.png"
            largePortraitSrcLeft="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_d_hi.png"
            smallPortraitSrcRight="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_m-l.png"
            largePortraitSrcRight="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_m-l_hi.png"
            portraitWidth={this.state.portraitWidth}
            portraitHeight={this.state.portraitHeight}
            magnifiedWidth={this.state.magnifiedWidth}
            magnifiedHeight={this.state.magnifiedHeight}
            statusLeft="ai"
            name="Lydia"
            statusRight="human"
            yearRight="2019"
          />
          <div className="portrait-diptych_text_wrapper">
            <div className="portrait-diptych_text">
              When the human and non-human figures are exhibited side by side,
              they of course become a meditation on the difficulty of
              distinguishing between what is real and what is not in an
              increasingly virtual world. Initially these technologies were
              engineered by corporations for the seemingly harmless purposes of
              selling video games, movies, and advertising. But inexorably,
              these technologies will be employed in ever more political and
              totalitarian contexts as they become increasingly difficult to
              detect and distinguish from photographic reality and factuality.
            </div>
          </div>
          <div className="gallery_diptych_container">
            <div className="gallery_diptych">
              <img
                src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/91841a70-8cd7-40ac-97b8-f81f791e4305_A.JPG"
                alt=""
              />
            </div>
            {/* <div className="gallery_diptych_caption">Digitally Exhibited</div> */}
          </div>
          <Diptych
            smallPortraitSrcLeft="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_v.png"
            largePortraitSrcLeft="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_v_hi.png"
            smallPortraitSrcRight="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_f.jpg"
            largePortraitSrcRight="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_f_hi.jpg"
            portraitWidth={this.state.portraitWidth}
            portraitHeight={this.state.portraitHeight}
            magnifiedWidth={this.state.magnifiedWidth}
            magnifiedHeight={this.state.magnifiedHeight}
            statusLeft="human"
            statusRight="ai"
            name="Faith"
            yearLeft="2019"
          />
          <div className="portrait-diptych_text_wrapper">
            <div className="portrait-diptych_text">
              “Decoded” was Wallace’s first series, and was influenced by the
              hyperrealism of Chuck Close, and by Thomas Ruff’s exploration (in
              the mid-1990s) of the construction of artificial faces in his
              "Anderes Porträt" series. In "Anderes Porträt", Ruff used a
              Minolta Montage Unit (a picture generating machine used by the
              German police in the 1970s to generate composite portraits) which
              Wallace found to be an eerie, analog predecessor technology to the
              generative artificial intelligence algorithms that Wallace himself
              had a personal role in researching and developing as part of his
              career as an A.I. entrepreneur. In dialogue with and in extension
              of Ruff’s 1990s composite portraits made from 1970s police
              techniques, Wallace’s “Decoded” series, made with 2020s artificial
              intelligence techniques, became a sort of act of personal
              disavowal and public critique—backed by the artist’s professional
              credibility in the field of Artificial Intelligence—of the use and
              applications of artificial intelligence in surveillance and law
              enforcement. Even a glancing appreciation of the technological
              sophistication behind Wallace’s “Decoded” series forces us to make
              no mistake: In the not-so-distant future , society will grapple
              with the ethics of using A.I.-based ‘
              <span className="popup_link" onClick={this.togglePopup}>
                predictive policing
              </span>
              ’ image generation technology, where composite-style ‘portraits’
              of ‘suspects’ are generated by A.I. on the basis of traces of DNA
              left at the scene of a crime.{" "}
            </div>
          </div>
          <Diptych
            smallPortraitSrcLeft="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_b.png"
            largePortraitSrcLeft="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_b_hi.png"
            smallPortraitSrcRight="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_dien.jpg"
            largePortraitSrcRight="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_dien_hi.png"
            portraitWidth={this.state.portraitWidth}
            portraitHeight={this.state.portraitHeight}
            magnifiedWidth={this.state.magnifiedWidth}
            magnifiedHeight={this.state.magnifiedHeight}
            statusLeft="ai"
            name="Yuja"
            statusRight="human"
            yearRight="2020"
          />
          <Diptych
            smallPortraitSrcLeft="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_bri.jpg"
            largePortraitSrcLeft="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_bri_hi.png"
            smallPortraitSrcRight="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_aa.jpg"
            largePortraitSrcRight="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_aa_hi.png"
            portraitWidth={this.state.portraitWidth}
            portraitHeight={this.state.portraitHeight}
            magnifiedWidth={this.state.magnifiedWidth}
            magnifiedHeight={this.state.magnifiedHeight}
            statusLeft="human"
            statusRight="ai"
            name="Tyra"
            yearLeft="2020"
          />
        </div>
        <div className="gallery_diptych_container bottom">
          <div className="gallery_diptych">
            <img
              src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/7577174a-9010-4ae2-a333-325608fb564c_B.JPG"
              alt=""
            />
          </div>
          {/* <div className="gallery_diptych_caption">Digitally Exhibited</div> */}
        </div>
        {/* <div className="test">"Alice is Living in a Simulation #3"</div> */}
        <div className="in_focus">in focus: digital humanoids</div>
        <div className="in_focus_redirect_prompt">
          click on a portrait thumbnail to see more detail
        </div>
        <div className="ai_gallery_wrap">
          <div className="ai_gallery_container">
            <div className="ai_img_container">
              <Link
                to={!this.state.mobile ? "decoded/Tyra" : "decoded/mobile/Tyra"}
              >
                <img
                  src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_aa.jpg"
                  alt=""
                />
              </Link>
              <Link
                to={!this.state.mobile ? "decoded/Tyra" : "decoded/mobile/Tyra"}
                style={{
                  textDecoration: "none",
                  color: "grey",
                }}
              >
                <div className="ai_img_caption">Tyra</div>
              </Link>
            </div>

            <div className="ai_img_container">
              <Link
                to={
                  !this.state.mobile ? "decoded/Lydia" : "decoded/mobile/Lydia"
                }
              >
                <img
                  src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_d.png"
                  alt=""
                />
              </Link>
              <Link
                to={
                  !this.state.mobile ? "decoded/Lydia" : "decoded/mobile/Lydia"
                }
                style={{
                  textDecoration: "none",
                  color: "grey",
                }}
              >
                <div className="ai_img_caption">Lydia</div>
              </Link>
            </div>

            <div className="ai_img_container">
              <Link
                to={!this.state.mobile ? "decoded/Yuja" : "decoded/mobile/Yuja"}
              >
                <img
                  src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_b.png"
                  alt=""
                />
              </Link>
              <Link
                to={!this.state.mobile ? "decoded/Yuja" : "decoded/mobile/Yuja"}
                style={{
                  textDecoration: "none",
                  color: "grey",
                }}
              >
                <div className="ai_img_caption">Yuja</div>
              </Link>
            </div>
            <div className="ai_img_container">
              <Link
                to={
                  !this.state.mobile ? "decoded/Faith" : "decoded/mobile/Faith"
                }
              >
                <img
                  src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_f.jpg"
                  alt=""
                />
              </Link>
              <Link
                to={
                  !this.state.mobile ? "decoded/Faith" : "decoded/mobile/Faith"
                }
                style={{
                  textDecoration: "none",
                  color: "grey",
                }}
              >
                <div className="ai_img_caption">Faith</div>
              </Link>
            </div>
            <div className="ai_img_container">
              <Link
                to={
                  !this.state.mobile
                    ? "decoded/Elaine"
                    : "decoded/mobile/Elaine"
                }
              >
                <img
                  src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_e.png"
                  alt=""
                />
              </Link>
              <Link
                to={
                  !this.state.mobile
                    ? "decoded/Elaine"
                    : "decoded/mobile/Elaine"
                }
                style={{
                  textDecoration: "none",
                  color: "grey",
                }}
              >
                <div className="ai_img_caption">Elaine</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
