import React from "react";
import { Link } from "react-router-dom";
import DropdownNav from "../../components/nav/dropdown_nav/DropdownNav";

import "./Terms.css";

const Terms = () => {
    return (
        <div className="terms_page">
            <DropdownNav />
            <div className="terms_section">
                <h1>terms and conditions</h1>
                <p className="">
                    Welcome to the Website of Daniel Nadler Gallery (“Daniel
                    Nadler,” “us,” and “we”). The following Terms and Conditions
                    (the “Terms and Conditions”) govern your use of this
                    Website. By using the Website, you accept and agree to these
                    Terms and Conditions and the Daniel Nadler Privacy Policy.
                    Click{" "}
                    <Link to="/privacy_policy" style={{ color: "black" }}>
                        here
                    </Link>{" "}
                    to view the Privacy Policy. We may revise the Terms and
                    Conditions at any time, without notice to you, such changes
                    to be effective immediately upon posting on the Website. If
                    you do not agree to the Terms and Conditions, you are not
                    granted permission to access or use this Website and must
                    exit immediately.
                </p>
                <p className="">
                    1. Proprietary Rights. As between you and us, we own, solely
                    or exclusively, all rights, title and interest in and to the
                    Website, all data, content, graphics, artwork, images,
                    photographs, code, audio clips, video clips, software and
                    other material on, in or made available through the Website
                    (the “Website Material”), as well as the look and feel,
                    design, selection, coordination, arrangement, and
                    organization of the Website Material (together with the
                    Website Material, the “Website Proprietary Content”),
                    including but not limited to any copyrights, trademark
                    rights, patent rights, database rights, moral rights and
                    other intellectual property and proprietary rights therein.
                    Under no circumstances will you have any rights of any kind
                    in or to the Website or the Website Proprietary Contents,
                    other than the right to use the Website in accordance with
                    these Terms and Conditions.
                </p>
                <p className="">
                    2. Limited License. You may access and view the content on
                    the Website on your computer or other device. Unless
                    otherwise specifically indicated in these Terms and
                    Conditions, use of the Website and the services offered on
                    or through the Website are only for your personal,
                    non-commercial use.
                </p>
                <p className="">
                    3. Prohibited Uses. You agree that any commercial or
                    promotional distribution, publication or exploitation of the
                    Website or any Website Proprietary Contents is strictly
                    prohibited unless you have received the express prior
                    written permission of an officer of Daniel Nadler or the
                    otherwise applicable rights holder. You further agree that
                    you will not (a) use the Website for any illegal or
                    unauthorized purposes that violate any laws (including
                    import, export and copyright laws); (b) download, post,
                    display, publish, copy, distribute, transmit, broadcast or
                    create derivative works from, or otherwise exploit any of
                    the Website Proprietary Contents; (c) alter, edit, delete,
                    remove, change the meaning or appearance of any of the
                    Website Proprietary Contents, including without limitation
                    the removal or alteration of any trademarks, trade names,
                    logos, service marks, copyright notices or any other
                    proprietary notices on any Website Proprietary Contents; (d)
                    attempt to gain unauthorized access to our computer system
                    or engage in any activity that interferes with the
                    performance of, or impairs the functionality of the Website
                    or any goods or services provided through the Website. We
                    reserve the right to suspend or terminate your access to
                    this Website and/or ability to use the services with or
                    without notice for failure to comply with these Terms and
                    Conditions, for providing us with untrue or inaccurate
                    information about yourself, for infringement upon any of our
                    proprietary rights, or for any other reason whatsoever or
                    for no reason.
                </p>
                <p className="">
                    4. Trademarks. The names, titles, trademarks, service marks,
                    and logos that appear on the Website, including without
                    limitation “Daniel Nadler,” are registered and unregistered
                    marks of Daniel Nadler (the "Trademarks"). You may not use
                    the Trademarks without our prior, written permission. The
                    trademarks of third parties may also appear on the Website
                    from time to time; you may not use these trademarks without
                    prior, written permission of their respective owners. You
                    acknowledge and agree that nothing on the Website grants any
                    right or license to use any of the Trademarks or may be
                    construed to mean that we have authority to grant any right
                    or license on behalf of any third party trademark owner.
                </p>
                <p className="">
                    5. Mailing List. You may use the Website without registering
                    or submitting any personally identifiable information. As a
                    service, we offer visitors to the Website the opportunity to
                    subscribe to the Daniel Nadler email newsletter (the
                    “Newsletter”). To subscribe to the Newsletter, you will be
                    asked to submit your name and email address (the “Subscriber
                    Information”). You may subscribe or unsubscribe at any time
                    by following the instructions on the Newsletter. The
                    Subscriber Information shall be subject to our Privacy
                    Policy (click here to view), which is incorporated herein by
                    reference as if set forth fully here. You acknowledge and
                    agree that you are solely responsible for the accuracy of
                    your Subscriber Information.
                </p>
                <p className="">
                    6. Linking. The Website may contain links to third­ party
                    web sites or services that are not owned or controlled by
                    Daniel Nadler or its affiliates. Daniel Nadler has no
                    control over, and assumes no responsibility for, the
                    content, privacy policies, or practices of any third party
                    web sites or services. You further acknowledge and agree
                    that Daniel Nadler shall not be responsible or liable,
                    directly or indirectly, for any damage or loss caused or
                    alleged to be caused by or in connection with use of or
                    reliance on any such content, goods or services available on
                    or through any such web sites or services. We strongly
                    advise you to read the terms and conditions and privacy
                    policies of any third ­party web sites or services that you
                    visit.
                </p>
                <p className="">
                    7. Disclaimer. Daniel Nadler, its subsidiaries, and
                    affiliates are not responsible for and do not guarantee the
                    accuracy or completeness of any Website Proprietary Content
                    or other items contained within the Website. We reserve the
                    right to immediately remove or make changes to any Website
                    Proprietary Contents for any reason or for no reason in our
                    sole discretion. WE PROVIDE THE WEBSITE ON AN "AS IS" BASIS.
                    YOUR USE OF THE WEBSITE IS AT YOUR OWN RISK. WE DISCLAIM ALL
                    WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
                    INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF
                    MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, COPYRIGHT
                    OWNERSHIP AND/OR NONINFRINGEMENT OF COPYRIGHTS OR OTHER
                    THIRD PARTY PROPRIETARY RIGHTS. WE DO NOT WARRANT THAT THE
                    WEBSITE WILL PROVIDE CONTINUOUS, PROMPT, SECURE, OR
                    ERROR-FREE SERVICE. WE MAKE REASONABLE, ONGOING EFFORTS TO
                    REVISE AND UPDATE THE WEBSITE, BUT ASSUME NO LIABILITY FOR
                    ANY ERRORS OR OMISSIONS, INCLUDING THE INACCURACY OF
                    CONTENT, OR FOR ANY DAMAGES OR LOSSES THAT YOU OR ANY THIRD
                    PARTY MAY INCUR AS A RESULT OF THE UNAVAILABILITY OF THE
                    WEBSITE. WE ASSUME NO RESPONSIBILITY, AND SHALL NOT BE
                    LIABLE FOR, ANY DAMAGES TO, OR VIRUSES WHICH MAY AFFECT,
                    YOUR COMPUTER EQUIPMENT OR OTHER PROPERTY ARISING FROM YOUR
                    USE OF THE WEBSITE.
                </p>
                <p className="">
                    8. Liability. IN NO EVENT, INCLUDING BUT NOT LIMITED TO
                    NEGLIGENCE, SHALL DAVID ZWIRNER, ITS AFFILIATES,
                    SUBSIDIARIES OR LICENSORS, OR ANY OF THEIR RESPECTIVE
                    MEMBERS, MANAGERS, DIRECTORS, OFFICERS, EMPLOYEES, AGENT AND
                    CONTRACTORS (COLLECTIVELY, THE “PROTECTED ENTITIES”) BE
                    LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
                    CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES OF ANY KIND
                    WHATSOEVER, INCLUDING LOST REVENUES OR LOST PROFITS, WHICH
                    MAY OR DOES RESULT FROM THE USE OF, ACCESS TO, OR INABILITY
                    TO USE THE WEBSITE, THE WEBSITE PROPRIETARY CONTENTS, USER
                    INFORMATION, SUBSCRIBER INFORMATION, THE SERVICES, PRODUCTS,
                    INFORMATION AND OTHER MATERIALS ON AND IN AND MADE AVAILABLE
                    THROUGH THE WEBSITE, REGARDLESS OF LEGAL THEORY, WHETHER OR
                    NOT YOU OR DAVID ZWIRNER HAD BEEN ADVISED OF THE POSSIBILITY
                    OR PROBABILITY OF SUCH DAMAGES, AND EVEN IF THE REMEDIES
                    OTHERWISE AVAILABLE FAIL OF THEIR ESSENTIAL PURPOSE. UNDER
                    NO CIRCUMSTANCES WILL THE TOTAL LIABILITY OF THE PROTECTED
                    ENTITIES TO YOU OR ANY OTHER PERSON OR ENTITY IN CONNECTION
                    WITH, BASED UPON, OR ARISING FROM THE WEBSITE, THE WEBSITE
                    PROPRIETARY CONTENTS, USER INFORMATION OR SUBSCRIBER
                    INFORMATION ON, IN, AND MADE AVAILABLE THROUGH THE WEBSITE,
                    OR THE SERVICES, INFORMATION, OR PRODUCTS OFFERED IN
                    CONNECTION THEREWITH EXCEED THE PRICE OF PRODUCTS PURCHASED
                    AND PAID FOR BY YOU VIA THE WEBSITE IN THE PRECEDING TWELVE
                    (12) MONTH PERIOD. SOME STATES DO NOT ALLOW THE LIMITATION
                    OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL
                    DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY
                    TO YOU. IF ANY PART OF THIS LIMITATION ON LIABILITY IS FOUND
                    TO BE INVALID OR UNENFORCEABLE FOR ANY REASON, THEN THE
                    TOTAL LIABILITY OF DAVID ZWIRNER AND ITS AFFILIATES,
                    SUBSIDIARIES OR ITS LICENSORS SHALL NOT EXCEED TEN DOLLARS
                    U.S. ($10 U.S.). If you are dissatisfied with the Website,
                    or with any of these Terms and Conditions, or feel we have
                    breached these Terms and Conditions, your sole and exclusive
                    remedy is to discontinue using the Website.
                </p>
                <p className="">
                    9. Indemnification. You shall indemnify, defend and hold the
                    Protected Entities harmless against any and all claims,
                    liabilities, costs and expenses (including reasonable
                    attorneys’ fees and disbursements) arising out of or
                    incurred in connection with your use of the Website or
                    products or services obtained through the Website, your
                    fraud, violation of law, negligence or willful misconduct,
                    or any breach by you of these Terms and Conditions.
                </p>
                <p className="">
                    10. Governing Law and Jurisdiction. These Terms and
                    Conditions represent the entire agreement between you and
                    David Zwirner with respect to the subject matter hereof, and
                    supersede any and all prior agreements, express and implied,
                    and will be governed by and construed in accordance with the
                    laws of the State of New York, United States of America,
                    without reference to its conflict of law rules. By
                    accessing, viewing, or using the services, works, content,
                    or materials on the Website, you consent and agree to (a)
                    the personal and exclusive jurisdiction of the federal and
                    state courts located in the County of New York and State of
                    New York, United States of America; (b) accept service of
                    process by personal delivery, mail, or email; and (c)
                    irrevocably waive the right to trial by jury and any
                    jurisdictional and venue defenses otherwise available.
                </p>
                <p className="">
                    11. Miscellaneous. The Website is not intended for children
                    under the age of 13. The Website is controlled and operated
                    from within the United States. Without limiting anything
                    else, we make no representation that the Website or Website
                    Proprietary Contents or other materials available on, in, or
                    through the Website are appropriate or available for use in
                    other locations, and access to them from territories where
                    they are illegal is prohibited. Persons who choose to access
                    the Website from other locations do so on their own
                    initiative, and are responsible for compliance with local
                    laws, if and to the extent local laws are applicable. The
                    waiver or failure of David Zwirner to exercise in any
                    respect any right provided hereunder shall not be deemed a
                    waiver of such right in the future or a waiver of any of
                    other rights established under these Terms and Conditions.
                    If any provision of the Terms and Conditions is found by a
                    court of competent jurisdiction to be invalid, the parties
                    nevertheless agree that the court should endeavor to give
                    effect to the parties’ intentions as reflected in the
                    provision, and the other provisions of the Terms and
                    Conditions remain in full force and effect. No person or
                    entity not party to this agreement will be deemed to be a
                    third party beneficiary of these Terms and Conditions or any
                    provision hereof. All rights not expressly granted by David
                    Zwirner herein are specifically and completely reserved.
                </p>
                <p className="">Dated: January 1, 2020</p>
            </div>
        </div>
    );
};

export default Terms;
