import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"

import "../Artwork.css"
let url
let name

const Human = (props) => {
  switch (props.match.path) {
    case "/work/decoded/marie":
      url = "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/marie.jpg"
      name = "Marie"
      break
    case "/work/decoded/valou":
      url = "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/valou.jpg"
      name = "Valou"
      break
    case "/work/decoded/lisa":
      url = "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/lisa.jpg"
      name = "Lisa"
      break
    default:
      url = "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/marie.jpg"
      name = "Marie"
      break
  }
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setTimeout(() => setLoading(true), 100)
  }, [])
  return (
    <div className="individual_artw" style={{ top: "500px" }}>
      <div className="artw_container">
        <img className={loading ? "artw loading" : "artw"} src={url} alt="" />
      </div>
      <div className="artw_desc">
        <p>‘{name}’. Human.</p>
        <p>
          This portrait is from Wallace's{" "}
          <Link
            to="/work/decoded"
            style={{ textDecoration: "none", color: "grey" }}
          >
            “Decoded”{" "}
          </Link>
          series. Each of the portraits in this series are imprinted with key
          excerpts from the ‘code’ with which the figures in the portraits are
          made: In the case of a figure created using generative artificial
          intelligence, you will see a key part of the algorithm involved in
          generating her overall form and composition; in the case of a human
          figure, you will see an excerpt from a key section of the DNA involved
          in ‘generating’ her biologically.
        </p>
      </div>
    </div>
  )
}

export default Human
