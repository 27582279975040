import React, { Component, useState, useEffect } from "react"
import DropdownNav from "../../components/nav/dropdown_nav/DropdownNav"
import Carousel from "../../components/carousel/Carousel"
import Artwork from "../../components/artwork/Artwork"
import Footer from "../../components/footer/Footer"

import { Link } from "react-router-dom"

import "./Home.css"

// const Home = () => {
//   const [loading, setLoading] = useState(false)
//   const [modalVisible, setModalVisible] = useState(false)
//   const [source, setSource] = useState("")

//   useEffect(() => {
//     document.title = "Daniel Wallace"
//     let timeout = setTimeout(() => {
//       setLoading({ loading: true })
//     }, 200)
//     return () => {
//       clearTimeout(timeout)
//     }
//   }, [])

//   const src1 =
//     "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_carou_wc_sl.jpg"

//   const toggleModal = () => {
//     setModalVisible({ modalVisible: true })
//   }

//   const closeModal = () => {
//     alert("closing")
//     setModalVisible({ modalVisible: false })
//   }
//   return (
//     <div className="homepage">
//       <DropdownNav />
//       <div className={loading ? "content fading-in" : "content"}>
//         <Carousel />
//         <div className="artist_intro">
//           <p>
//             'Daniel Wallace'—the alter ego of a Harvard PhD scientist who
//             founded one of the highest-valued artificial intelligence companies
//             in history (
//             <Link
//               style={{ color: "black" }}
//               to="/daniel_wallace"
//               target="_blank"
//             >
//               bio
//             </Link>
//             )—is a multidisciplinary artist based out of London.
//           </p>
//           <p>
//             Wallace works in digital painting, photography, video, text,
//             sculpture, and performance. Wallace’s process involves working with
//             generative artificial intelligence algorithms, physical world
//             capture methods (including photography and motion capture), and
//             traditional digital painting and digital sculpting techniques,
//             bridging surrealism, photorealism, CGI, and hyperrealism. Wallace
//             juxtaposes both new and classical techniques that have never been
//             seen together before in order to produce new relationships and new
//             visual realities.
//           </p>
//         </div>
//         <Artwork
//           portraitContainerClass="homepage_artw desktop_hp"
//           src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_carou_wc_sl.jpg"
//         />
//         <Artwork
//           portraitContainerClass="homepage_artw desktop_hp"
//           src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_h_carou_ana.jpg"
//         />
//         <div className="hp_sc_wrapper desktop_hp">
//           <div className="hp_sc_container">
//             <img
//               src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_4055.jpg"
//               alt=""
//             />
//             <div className="hp_sc_caption">executed in bronze</div>
//           </div>
//         </div>
//         <div className="hp_sc_wrapper desktop_hp">
//           <div className="hp_sc_container">
//             <img
//               src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_wco_14.jpg"
//               alt=""
//             />
//             <div className="hp_sc_caption">
//               <div className="">"alice is living in a simulation #3"</div>
//             </div>
//           </div>
//         </div>
//         <div className="homepage_diptych desktop_hp">
//           <div className="hp_diptych_container">
//             <div className="hp_diptych">
//               <img
//                 src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_bri_hi.png"
//                 alt=""
//               />
//               <div className="hp_decoded_caption">
//                 Human. Shot at Wallace’s studio, 2020
//               </div>
//             </div>
//             <div className="hp_diptych">
//               <img
//                 src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_aa_1900.png"
//                 alt=""
//               />
//               <div className="hp_decoded_caption">
//                 "Tyra". This person does not exist. She was created by Wallace
//                 using a combination of generative artificial intelligence
//                 algorithms and digital painting
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="homepage_diptych desktop_hp">
//           <div className="hp_diptych_container">
//             <div className="hp_diptych">
//               <img
//                 src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_tia_ml.JPG"
//                 alt=""
//               />
//             </div>
//             <div className="hp_diptych">
//               <img
//                 src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_tia_j.JPG"
//                 alt=""
//               />
//             </div>
//           </div>
//           <div className="hp_tia_caption">
//             "Truth in Advertising". Captured in the studio, then processed
//             through an emotion recognition and classification A.I. that Wallace
//             built with the same technology as is used by governments and major
//             corporations. Influenced by the work of Barbara Kruger
//           </div>
//         </div>
//         <div
//           className={modalVisible ? "hp_modal" : "hp_modal hidden"}
//           onClick={closeModal}
//         >
//           <div className="newspopup_content">
//             <img
//               className="news_collage"
//               src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dna.png"
//               alt=""
//             />
//           </div>
//         </div>
//         <Artwork
//           onClick={toggleModal}
//           portraitContainerClass="homepage_artw mobile_hp"
//           src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_carou_wc_sl.jpg"
//         />
//         <Artwork
//           onClick={closeModal}
//           portraitContainerClass="homepage_artw mobile_hp"
//           src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_h_carou_ana.jpg"
//         />
//         <div className="hp_sc_wrapper mobile_hp">
//           <div className="hp_sc_container">
//             <img
//               src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_4055.jpg"
//               alt=""
//             />
//             <div className="hp_sc_caption">executed in bronze</div>
//           </div>
//         </div>
//         <div className="hp_sc_wrapper mobile_hp">
//           <div className="hp_sc_container">
//             <img
//               src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_wco_14.jpg"
//               alt=""
//             />
//             <div className="hp_sc_caption">
//               <div className="">"alice is living in a simulation #3"</div>
//             </div>
//           </div>
//         </div>
//         <div className="homepage_diptych mobile_hp">
//           <div className="hp_diptych_container">
//             <div className="hp_diptych">
//               <img
//                 src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_bri_hi.png"
//                 alt=""
//               />
//               <div className="hp_decoded_caption">
//                 Human. Shot at Wallace’s studio, 2020
//               </div>
//             </div>
//             <div className="hp_diptych">
//               <img
//                 src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_aa_1900.png"
//                 alt=""
//               />
//               <div className="hp_decoded_caption">
//                 "Tyra". This person does not exist. She was created by Wallace
//                 using a combination of generative artificial intelligence
//                 algorithms and digital painting
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="homepage_diptych mobile_hp">
//           <div className="hp_diptych_container">
//             <div className="hp_diptych">
//               <img
//                 src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_tia_ml.JPG"
//                 alt=""
//               />
//             </div>
//             <div className="hp_diptych">
//               <img
//                 src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_tia_j.JPG"
//                 alt=""
//               />
//             </div>
//           </div>
//           <div className="hp_tia_caption">
//             "Truth in Advertising". Captured in the studio, then processed
//             through an emotion recognition and classification A.I. that Wallace
//             built with the same technology as is used by governments and major
//             corporations. Influenced by the work of Barbara Kruger
//           </div>
//         </div>

//         <Footer />
//       </div>
//     </div>
//   )
// }

// export default Home

export default class Home extends Component {
  state = {
    loading: false,
    modalVisible: false,
    modalSrc: "",
    src1: "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_carou_wc_sl.jpg",
    src2: "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_h_carou_ana.jpg",
    src3: "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_4055.jpg",
    src4: "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_wco_14.jpg",
    src5: "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_hp_dec_mod.png",
    src6: "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_hp_class_mod.png",
    src7: "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_h_carou_ana2.png",
  }
  componentDidMount() {
    document.title = "Daniel Wallace"
    let timeout = setTimeout(() => {
      this.setState({ loading: true })
    }, 200)
    return () => {
      clearTimeout(timeout)
    }
  }

  toggleModal1 = () => {
    document.body.classList.add("modal-open")
    this.props.hideNavBar()
    this.setState({
      modalVisible: !this.state.modalVisible,
      modalSrc:
        "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_hp_hum_mod.jpg",
    })
  }
  toggleModal2 = () => {
    document.body.classList.add("modal-open")
    this.props.hideNavBar()
    this.setState({
      modalVisible: !this.state.modalVisible,
      modalSrc:
        "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_hp_an_mod.jpg",
    })
  }
  toggleModal3 = () => {
    document.body.classList.add("modal-open")
    this.props.hideNavBar()
    this.setState({
      modalVisible: !this.state.modalVisible,
      modalSrc: this.state.src3,
    })
  }
  toggleModal4 = () => {
    document.body.classList.add("modal-open")
    this.props.hideNavBar()
    this.setState({
      modalVisible: !this.state.modalVisible,
      modalSrc: this.state.src4,
    })
  }
  toggleModal5 = () => {
    document.body.classList.add("modal-open")
    this.props.hideNavBar()
    this.setState({
      modalVisible: !this.state.modalVisible,
      modalSrc: this.state.src5,
    })
  }
  toggleModal6 = () => {
    document.body.classList.add("modal-open")
    this.props.hideNavBar()
    this.setState({
      modalVisible: !this.state.modalVisible,
      modalSrc: this.state.src6,
    })
  }
  toggleModal7 = () => {
    document.body.classList.add("modal-open")
    this.props.hideNavBar()
    this.setState({
      modalVisible: !this.state.modalVisible,
      modalSrc:
        "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_hp_an2_mod.jpg",
    })
  }
  closeModal = () => {
    document.body.style.backgroundColor = "white"
    document.body.classList.remove("modal-open")

    this.props.hideNavBar()
    this.setState({ modalVisible: false })
  }

  render() {
    const { src1, src2, src3, src7 } = this.state
    return (
      <div className="homepage">
        <div className={this.state.loading ? "content fading-in" : "content"}>
          <Carousel />
          <div className="artist_intro">
            <p>
              Daniel Wallace is a multidisciplinary artist working in digital
              painting, photography, video, sculpture, and performance.
              Wallace’s process involves working with physical world capture
              methods (including photography and motion capture) and digital
              painting/sculpting techniques, bridging surrealism, photorealism,
              CGI, and hyperrealism. Wallace juxtaposes both new and classical
              techniques that have never been seen together before in order to
              produce new relationships and new visual realities.
            </p>
          </div>
          <Artwork
            portraitContainerClass="homepage_artw desktop_hp"
            src={src1}
          />
          <Artwork
            portraitContainerClass="homepage_artw desktop_hp"
            src={src2}
          />
          <div className="hp_sc_wrapper desktop_hp">
            <div className="hp_sc_container">
              <img
                src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_4055.jpg"
                alt=""
              />
              <div className="hp_sc_caption">executed in bronze</div>
            </div>
          </div>
          <Artwork
            portraitContainerClass="homepage_artw desktop_hp"
            src={src7}
          />
          <div className="hp_sc_wrapper desktop_hp">
            <div className="hp_sc_container">
              <img
                src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/alice_4.jpg"
                alt=""
              />
              <div className="hp_sc_caption">
                <div className="">"alice is living in a simulation #4"</div>
              </div>
            </div>
          </div>
          <div className="homepage_diptych desktop_hp">
            <div className="hp_diptych_container">
              <div className="hp_diptych">
                <img
                  src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_bri_hi.png"
                  alt=""
                />
                <div className="hp_decoded_caption">
                  Human. Shot at Wallace’s studio, 2020
                </div>
              </div>
              <div className="hp_diptych">
                <img
                  src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_aa_1900_1.png"
                  alt=""
                />
                <div className="hp_decoded_caption">
                  "Tyra". This person does not exist. She was created by Wallace
                  using a combination of generative artificial intelligence
                  algorithms and digital painting
                </div>
              </div>
            </div>
          </div>
          {/* <div className="homepage_diptych desktop_hp">
            <div className="hp_diptych_container">
              <div className="hp_diptych">
                <img
                  src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_tia_ml.JPG"
                  alt=""
                />
              </div>
              <div className="hp_diptych">
                <img
                  src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_tia_j.JPG"
                  alt=""
                />
              </div>
            </div>
            <div className="hp_tia_caption">
              "Truth in Advertising". Captured in the studio, then processed
              through an emotion recognition and classification A.I. that
              Wallace built with the same technology as is used by governments
              and major corporations. Influenced by the work of Barbara Kruger
            </div>
          </div> */}
          <div
            className={this.state.modalVisible ? "hp_modal" : "hp_modal hidden"}
            onClick={this.closeModal}
          >
            <div className="hp_modal_img_container">
              <img className="hp_modal_img" src={this.state.modalSrc} alt="" />
            </div>
          </div>
          <Artwork
            onClick={this.toggleModal1}
            portraitContainerClass="homepage_artw mobile_hp"
            src={src1}
          />
          <Artwork
            onClick={this.toggleModal2}
            portraitContainerClass="homepage_artw mobile_hp"
            src={src2}
          />
          <div className="hp_sc_wrapper mobile_hp" onClick={this.toggleModal3}>
            <div className="hp_sc_container">
              <img src={src3} alt="" />
              <div className="hp_sc_caption">executed in bronze</div>
            </div>
          </div>
          <Artwork
            onClick={this.toggleModal7}
            portraitContainerClass="homepage_artw mobile_hp"
            src={src7}
          />
          <div className="hp_sc_wrapper mobile_hp" onClick={this.toggleModal4}>
            <div className="hp_sc_container">
              <img
                src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_wco_14.jpg"
                alt=""
              />
              <div className="hp_sc_caption">
                <div className="">"alice is living in a simulation #4"</div>
              </div>
            </div>
          </div>
          <div
            className="homepage_diptych mobile_hp"
            onClick={this.toggleModal5}
          >
            <div className="hp_diptych_container">
              <div className="hp_diptych">
                <img
                  src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_bri_hi.png"
                  alt=""
                />
                <div className="hp_decoded_caption">
                  Human. Shot at Wallace’s studio, 2020
                </div>
              </div>
              <div className="hp_diptych">
                <img
                  src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_aa_1900_1.png"
                  alt=""
                />
                <div className="hp_decoded_caption">
                  "Tyra". This person does not exist. She was created by Wallace
                  using a combination of generative artificial intelligence
                  algorithms and digital painting
                </div>
              </div>
            </div>
          </div>
          {/* <div
            className="homepage_diptych mobile_hp"
            onClick={this.toggleModal6}
          >
            <div className="hp_diptych_container">
              <div className="hp_diptych">
                <img
                  src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_tia_ml.JPG"
                  alt=""
                />
              </div>
              <div className="hp_diptych">
                <img
                  src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_tia_j.JPG"
                  alt=""
                />
              </div>
            </div>
            <div className="hp_tia_caption">
              "Truth in Advertising". Captured in the studio, then processed
              through an emotion recognition and classification A.I. that
              Wallace built with the same technology as is used by governments
              and major corporations. Influenced by the work of Barbara Kruger
            </div>
          </div> */}

          <Footer />
        </div>
      </div>
    )
  }
}
