import React, { useState, useEffect } from "react"
import ReactImageMagnify from "react-image-magnify"

// import "../Artwork.css"
import "./Diptych.css"

const Diptych = (props) => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setTimeout(() => setLoading(true), 100)
  })
  return (
    <div
      className={loading ? "portraits_wrapper fading-in" : "portraits_wrapper"}
      style={{ top: "500px" }}
    >
      <div className="diptych_container">
        <div className="portrait_l">
          <ReactImageMagnify
            {...{
              smallImage: {
                alt: "dw",
                src: props.smallPortraitSrcLeft,
                isFluidWidth: true,
                // width: props.portraitWidth,
                // height: props.portraitHeight,
              },
              largeImage: {
                src: props.largePortraitSrcLeft,
                width: props.magnifiedWidth,
                height: props.magnifiedHeight,
              },
              imageClassName: props.portraitClass,
              enlargedImagePosition: "over",
            }}
          />
          {props.statusLeft === `ai` ? (
            <div className="portrait_caption_left">
              <div className="diptych_caption">
                {props.name ? `"${props.name}". ` : ""}This person does not
                exist. She was created by Wallace using a combination of
                generative artificial intelligence algorithms and digital
                painting
              </div>
            </div>
          ) : (
            <div className="portrait_caption_left">
              <div className="diptych_caption">
                Human. Shot at Wallace’s studio, {props.yearLeft}
              </div>
            </div>
          )}
        </div>
        <div className="portrait_r">
          <ReactImageMagnify
            {...{
              smallImage: {
                alt: "dw",
                src: props.smallPortraitSrcRight,
                isFluidWidth: true,
                // width: props.portraitWidth,
                // height: props.portraitHeight,
              },
              largeImage: {
                src: props.largePortraitSrcRight,
                width: props.magnifiedWidth,
                height: props.magnifiedHeight,
              },
              imageClassName: props.portraitClass,
              enlargedImagePosition: "over",
            }}
          />
          {props.statusRight === `ai` ? (
            <div className="portrait_caption_right">
              <div className="diptych_caption">
                {props.name ? `"${props.name}". ` : ""}This person does not
                exist. She was created by Wallace using a combination of
                generative artificial intelligence algorithms and digital
                painting
              </div>
            </div>
          ) : (
            <div className="portrait_caption_right">
              <div className="diptych_caption">
                Human. Shot at Wallace’s studio, {props.yearRight}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Diptych
