import React from "react"
import { Link } from "react-router-dom"

import "./Thumbnail.css"

const Thumbnail = (props) => {
  return (
    <div className={props.className}>
      <Link
        to={`/work/${props.to}`}
        className=""
        style={{ textDecoration: "none", color: "black" }}
      >
        <img src={props.src} alt="" />
      </Link>
      <Link
        to={`/work/${props.to}`}
        className=""
        style={{ textDecoration: "none", color: "black" }}
      >
        <p className="project_name">{props.projectName}</p>
      </Link>
    </div>
  )
}

export default Thumbnail
