import React, { useEffect, useState } from "react"
import Thumbnail from "../../components/work/thumbnail/Thumbnail"

import "./SelectedWork.css"

const Work = (props) => {
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    document.title = "Daniel Wallace | Work"
    props.setWorkToActive()
    let timeout = setTimeout(() => {
      setLoading({ loading: true })
    }, 200)
    return () => {
      clearTimeout(timeout)
      props.setWorkToInactive()
    }
  }, [])
  return (
    <div
      className={loading ? "selected_work_page loading" : "selected_work_page"}
    >
      <div className="work_thumb_container">
        <div className="row">
          <Thumbnail
            className="thumbnail"
            to="the_simulacrum_is_real"
            src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/simulacrum_th.jpg"
            projectName="the simulacrum is real"
          />
          <Thumbnail
            className="thumbnail"
            to="humanoids"
            src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_w_hu_th.jpg"
            projectName="humanoids"
          />
          <Thumbnail
            className="thumbnail"
            to="sculpture"
            src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_w_s_th.jpg"
            projectName="sculpture"
          />
        </div>
        <div className="row">
          <Thumbnail
            className="thumbnail"
            to="classification"
            src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_w_tia_th.png"
            projectName="classification"
          />
          <Thumbnail
            className="thumbnail dig_p"
            to="hyperrealistic_digital_paintings"
            src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_w_if_th.png"
            projectName="hyperrealistic digital paintings"
          />
        </div>

        {/* </div>
        <div className="row"> */}

        {/* <Thumbnail
            className="thumbnail"
            to="alice_is_living_in_a_simulation"
            src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_ailias_th.jpg"
            projectName="alice is living in a simulation"
          /> */}

        <div className="row">
          <Thumbnail
            className="thumbnail"
            to="decoded"
            src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_w_dec_th.png"
            projectName="decoded"
          />
          <Thumbnail
            className="thumbnail"
            to="eulogy"
            src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_e_th.png"
            projectName="eulogy"
          />
        </div>
      </div>
      {/* <div className="zoom_capt">
        'Yuja'. This person does not exist. Mixed Media: Digital painting
        executed on top of a facial composition that was realized using
        generative artificial intelligence algorithms and CGI tools.
      </div>
      <div className="zoom_test">
        This person does not exist. She was created by Wallace using a
        combination of generative artificial intelligence algorithms and digital
        painting. Each of the portraits in this series are imprinted with key
        excerpts from the ‘code’ with which the figures in the portraits are
        made: In the case of this figure, you see along the left edge of the
        frame a key part of the artificial intelligence algorithm involved in
        generating her overall form and composition.{" "}
      </div> */}
    </div>
  )
}

export default Work
