import React from "react"
import { Link } from "react-router-dom"
import "./Nav.css"

const Nav = (props) => {
  return (
    <div
      className={props.isNavHidden ? "nav_container hidden" : "nav_container"}
    >
      <div className={props.isNavColorGrey ? "nav grey" : "nav white"}>
        <div className="logo_area">
          <Link to="/" style={{ textDecoration: "none" }}>
            {/* <div className="link logo">daniel wallace</div> */}
            <img
              className="wallace_corp_logo"
              src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/wallace-corp-logo-final.png"
              alt=""
            />
          </Link>
        </div>
        <div
          className="nav_links"
          are_links_visible={`${props.is_links_visible}`}
        >
          <Link to="/daniel_wallace" style={{ textDecoration: "none" }}>
            <div className="link about_link">about</div>
          </Link>
          <Link to="/work" style={{ textDecoration: "none" }}>
            <div className="link video_link">work</div>
          </Link>
          <Link to="/contact" style={{ textDecoration: "none" }}>
            <div className="link contact_link">contact</div>
          </Link>
        </div>
      </div>
    </div>
  )
}
export default Nav
