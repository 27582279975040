import React from "react"

const Modal = (props) => {
  if (!props.show) {
    return null
  }
  return (
    <div className={props.modalClass} onClick={props.onClick} show={props.show}>
      <div className={props.imgContainerClass}>
        <img className={props.imgClass} src={props.modalImgSrc} alt="" />
      </div>
    </div>
  )
}

export default Modal
