import React, { useEffect, useState } from "react"

import "./NeonBrutalism.css"

const NeonBrutalism = () => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    document.title = "Daniel Wallace | Neon Brutalism"

    let timeout = setTimeout(() => {
      setLoading({ loading: true })
    }, 300)
    return () => {
      clearTimeout(timeout)
    }
  }, [])
  return (
    <div className={loading ? "neon_brut_page loading" : "neon_brut_page"}>
      <div className="neon_brut_container">
        <div className="neon_brut_header">alice is living in a simulation</div>
        <div className="neon_brut_artw_container">
          <img
            src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_wco_14.jpg"
            alt=""
          />
        </div>
        <div className="neon_brut_artw_caption">
          "Alice is Living in a Simulation #3"
        </div>
      </div>
    </div>
  )
}

export default NeonBrutalism
