import React, { useState, useEffect, useCallback } from "react"
import { useTransition, animated } from "react-spring"

import "./Carousel.css"

const Carousel = (props) => {
  const [images] = useState([
    {
      url: props.url1,
      id: 1,
      class_name: "tia_carousel_slide",
    },
    {
      url: props.url2,
      id: 2,
      class_name: "tia_carousel_slide",
    },
  ])
  const [idx, setIdx] = useState(0)
  const [source, setSource] = useState(images[idx].url)
  const [visible, setVisibility] = useState(false)
  const transitions = useTransition(images[idx], (item) => item.id, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { tension: 220, friction: 120 },
  })
  const slide = transitions.map(({ item, props, key }) => {
    return (
      <animated.img
        className={item.class_name}
        style={props}
        key={key}
        src={`${item.url}`}
        alt=""
      />
    )
  })

  useEffect(() => {
    setSource(images[idx].url)
  }, [idx, images])

  useEffect(() => {
    let interval = setInterval(() => {
      setIdx((index) => (index + 1) % images.length)
    }, 7000)
    return () => {
      clearInterval(interval)
    }
  })

  const handleKeyEvent = useCallback((e) => {
    switch (e.keyCode) {
      case 37:
        showPrevSlide()
        break
      case 39:
        showNextSlide()
        break
      default:
        return
    }
  })

  useEffect(() => {
    window.addEventListener("keydown", handleKeyEvent)
    return () => {
      window.removeEventListener("keydown", handleKeyEvent)
    }
  }, [handleKeyEvent])

  useEffect(() => {})

  const showArrows = () => {
    setVisibility(true)
  }

  const hideArrows = () => {
    setVisibility(false)
  }

  const showPrevSlide = () => {
    if (idx === 0) {
      setIdx(images.length - 1)
    } else {
      setIdx(idx - 1)
    }
    setSource(images[idx])
  }

  const showNextSlide = () => {
    if (idx === images.length - 1) {
      setIdx(0)
    } else {
      setIdx(idx + 1)
    }
    setSource(images[idx])
  }

  return (
    <div className="tia_carousel_container">
      <div className="tia_carousel">
        <div
          className="tia_slide_container"
          onMouseEnter={showArrows}
          onMouseLeave={hideArrows}
        >
          {slide}
          <div
            className={
              visible
                ? "tia_slider_buttons_container visible_container"
                : "tia_slider_buttons_container hidden_container"
            }
          >
            <div className="tia_slider_button prev" onClick={showPrevSlide}>
              <span className="tia_chevron left"></span>
            </div>
            <div className="tia_slider_button next" onClick={showNextSlide}>
              <span className="tia_chevron right"></span>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="tia_slider_buttons_container-sm_screen">
        <div className="tia_slider_button prev" onClick={showPrevSlide}>
          <span className="tia_chevron left"></span>
        </div>
        <div className="tia_slider_button prev" onClick={showNextSlide}>
          <span className="tia_chevron right"></span>
        </div>
      </div> */}
    </div>
  )
}

export default Carousel
