import React from "react"

import "./IndividualSculpture.css"

const IndividualSculpture = (props) => {
  return (
    <div className="sculpture_container" onClick={props.onClick}>
      <img src={props.src} alt="" />
    </div>
  )
}

export default IndividualSculpture
