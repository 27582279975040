import React, { Component, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import "./DropdownNav.css"

export default class DropdownNav extends Component {
  constructor(props) {
    super(props)
    this.state = { isOpen: false }
  }

  toggleMenu = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  closeMenu = () => {
    this.setState({ isOpen: false })
  }
  render() {
    return (
      <div
        className={
          this.props.isNavHidden ? "dropdown_menu hidden" : "dropdown_menu"
        }
      >
        <div
          className={
            this.props.isNavColorGrey
              ? "dropdown_nav grey"
              : "dropdown_nav white"
          }
        >
          <div
            className={this.state.isOpen ? "menu_icon  open" : "menu_icon"}
            onClick={this.toggleMenu}
          >
            <div></div>
            <div></div>
            <div></div>
          </div>
          <Link to="/" style={{ textDecoration: "none" }}>
            {/* <div className="link dropdown_logo" onClick={this.closeMenu}>
              daniel wallace */}
            <img
              className="wallace_corp_logo_dropdown"
              src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/wallace-corp-logo-final.png"
              alt=""
            />
            {/* </div> */}
          </Link>
          <div className="menu_icon mock_icon">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div className={this.state.isOpen ? "menu visible" : "menu hidden"}>
          <div
            className="nav_items"
            is_about_active={`${this.props.is_about_active}`}
            is_work_active={`${this.props.is_work_active}`}
            is_contact_active={`${this.props.is_contact_active}`}
          >
            <Link to="/daniel_wallace" style={{ textDecoration: "none" }}>
              <div
                onClick={this.toggleMenu}
                className={this.props.is_about_active ? "link active" : "link"}
              >
                about
              </div>
            </Link>
            <Link to="/work" style={{ textDecoration: "none" }}>
              <div
                onClick={this.toggleMenu}
                className={this.props.is_work_active ? "link active" : "link"}
              >
                work
              </div>
            </Link>
            <Link to="/contact" style={{ textDecoration: "none" }}>
              <div
                onClick={this.toggleMenu}
                className={
                  this.props.is_contact_active ? "link active" : "link"
                }
              >
                contact
              </div>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

// const DropdownNav = (props) => {
//   const [open, setOpen] = useState(false)

//   const toggleMenu = () => {
//     console.log("ok")
//     setOpen({ open: !open })
//   }

//   const hideMenu = () => {
//     setOpen({ open: false })
//   }

//   return (
//     <div
//       className={props.isNavHidden ? "dropdown_menu hidden" : "dropdown_menu"}
//     >
//       <div
//         className={
//           props.isNavColorGrey ? "dropdown_nav grey" : "dropdown_nav white"
//         }
//       >
//         <div
//           className={open ? "menu_icon  open" : "menu_icon"}
//           onClick={toggleMenu}
//         >
//           <div></div>
//           <div></div>
//           <div></div>
//         </div>
//         <Link to="/" style={{ textDecoration: "none" }}>
//           <div className="link dropdown_logo" onClick={hideMenu}>
//             daniel wallace
//             {/* <img
//               className="wallace_logo_dropdown"
//               src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/wallace-corp-logo.png"
//               alt=""
//             /> */}
//           </div>
//         </Link>
//         <div className="menu_icon mock_icon">
//           <div></div>
//           <div></div>
//           <div></div>
//         </div>
//       </div>
//       <div className={open ? "menu visible" : "menu hidden"}>
//         <div
//           className="nav_items"
//           is_about_active={`${props.is_about_active}`}
//           is_video_active={`${props.is_video_active}`}
//           is_contact_active={`${props.is_contact_active}`}
//         >
//           <Link to="/daniel_wallace" style={{ textDecoration: "none" }}>
//             <div
//               onClick={toggleMenu}
//               className={props.is_about_active ? "link active" : "link"}
//             >
//               about
//             </div>
//           </Link>
//           <Link to="/work" style={{ textDecoration: "none" }}>
//             <div
//               onClick={toggleMenu}
//               className={props.is_video_active ? "link active" : "link"}
//             >
//               work
//             </div>
//           </Link>
//           <Link to="/contact" style={{ textDecoration: "none" }}>
//             <div
//               onClick={hideMenu}
//               className={props.is_contact_active ? "link active" : "link"}
//             >
//               contact
//             </div>
//           </Link>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default DropdownNav
