import React, { useState, useEffect } from "react"

import "./Eulogy.css"

const Eulogy = () => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading({ loading: true })
    }, 200)
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return (
    <div className={loading ? "eulogy_page loading" : "eulogy_page"}>
      <div className="eulogy_container">
        <div className="eulogy_header">eulogy</div>
        <div className="eulogy_artw_container">
          <img
            src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_e_soe.jpg"
            alt=""
          />
          <div className="eulogy_caption">"sleep of death"</div>
        </div>
        <div className="eulogy_artw_container">
          <img
            src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_e_sm.jpg"
            alt=""
          />
          <div className="eulogy_caption">"summer"</div>
        </div>
      </div>
    </div>
  )
}

export default Eulogy
