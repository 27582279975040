import React, { Component } from "react"
import { Link } from "react-router-dom"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"

import "../artwork/ai/Ai"

class Elaine extends Component {
  state = { loading: false, x: 0, y: 0, desktop: true }
  componentDidMount() {
    console.log(window.innerWidth, window.innerHeight, this.state.desktop)
    document.title = "Daniel Wallace | Elaine"

    if (window.innerWidth < 400) {
      this.setState({ desktop: false })
    } else {
      this.setState({ desktop: true })
    }
    window.scrollTo(0, 0)
    this.props.hideNavBar()
    setTimeout(() => {
      this.setState({ loading: true })
    }, 400)
  }
  componentWillUnmount() {
    this.props.hideNavBar()
  }
  render() {
    return (
      <div
        className={
          this.state.loading
            ? "individual_ai_page"
            : "individual_ai_page hidden"
        }
      >
        <TransformWrapper
          options={{ minScale: 0.2, maxScale: 9 }}
          pan={{
            velocityEqualToMove: true,
            velocitySensitivity: 0.5,
          }}
          scale={1}
          positionY={-640}
        >
          {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
            <React.Fragment>
              <div className="arrow_wrapper">
                <Link to={this.props.to} style={{ textDecoration: "none" }}>
                  <div className="arrow_container">
                    <i className="fas fa-chevron-left"></i>
                  </div>
                </Link>
              </div>
              <div className="magnifiers">
                <button onClick={zoomIn}>+</button>
                <button onClick={zoomOut}>-</button>
              </div>
              <TransformComponent>
                <img
                  className="full_ai_img"
                  src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_e_zoom.png"
                  alt="elaine"
                />
              </TransformComponent>
            </React.Fragment>
          )}
        </TransformWrapper>
      </div>
    )
  }
}

export default Elaine
