// ************** to review  **************

import React, { Component } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import { Link } from "react-router-dom";

import "./Ai.css";
let smallPortraitSrc;
let largePortraitSrc;
let url;
let name;

// const Ai = (props) => {
//   switch (props.match.path) {
//     case "/work/decoded/elaine":
//       url = "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/elaine.jpg"
//       name = "Elaine"
//       break
//     case "/work/decoded/juliet":
//       url = "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/juliet.jpg"
//       name = "Juliet"
//       break
//     case "/work/decoded/cora":
//       url = "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/cora.jpg"
//       name = "Cora"
//       break
//     case "/work/decoded/faith":
//       url = "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/faith.jpg"
//       name = "Faith"
//       break
//     case "/work/decoded/bella":
//       url = "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/bella.jpg"
//       name = "Bella"
//       break
//     case "/work/decoded/jasmine":
//       url =
//         "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/jasmine.jpg"
//       name = "Jasmine"
//       break
//     case "/work/decoded/jada":
//       url = "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/jada.jpg"
//       name = "Jada"
//       break
//     case "/work/decoded/davina":
//       url = "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/davina.jpg"
//       name = "Davina"
//       break
//     case "/work/decoded/Tyra2045":
//       smallPortraitSrc =
//         "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_aa.jpg"
//       largePortraitSrc =
//         "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_aa_zoom.png"
//       name = "Tyra, 2045"
//       break
//     case "/work/decoded/Lydia2045":
//       smallPortraitSrc =
//         "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_d.png"
//       largePortraitSrc =
//         "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_d_hi.png"
//       name = "Lydia, 2045"
//       break
//     case "/work/decoded/Faith2045":
//       smallPortraitSrc =
//         "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_f.jpg"
//       largePortraitSrc =
//         "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_f_hi.jpg"
//       name = "Faith, 2045"
//       break
//     case "/work/decoded/Elaine2045":
//       smallPortraitSrc =
//         "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_e.png"
//       largePortraitSrc =
//         "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_e_hi.png"
//       name = "Elaine, 2045"
//       break
//     case "/work/decoded/Yuja2045":
//       smallPortraitSrc =
//         "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_b.png"
//       largePortraitSrc =
//         "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_b_hi.png"
//       name = "Yuja, 2045"
//       break
//     case "/work/decoded/georgia":
//       url =
//         "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/georgia.jpg"
//       name = "Georgia"
//       break
//     case "/work/decoded/willow":
//       url = "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/willow.jpg"
//       name = "Willow"
//       break
//     default:
//       url = "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/elaine.jpg"
//       name = "Elaine"
//       break
//   }
//   const [loading, setLoading] = useState(false)
//   useEffect(() => {
//     setTimeout(() => setLoading(true), 100)
//   })

//   useEffect(() => {
//     window.scrollTo(0, 0)
//     console.log(props)
//   }, [])

//   return (
//     <div className="individual_ai_page">
//       <div className="arrow_wrapper">
//         <Link to="/work/decoded" style={{ textDecoration: "none" }}>
//           <div className="arrow_container">
//             <i className="fas fa-chevron-left"></i>
//           </div>
//         </Link>
//       </div>
//       <TransformWrapper>
//         {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
//           <React.Fragment>
//             <div className="tools">
//               <button onClick={zoomIn}>+</button>
//               <button onClick={zoomOut}>-</button>
//             </div>
//             <TransformComponent>
//               <img className="full_ai_img" src={largePortraitSrc} alt="test" />
//             </TransformComponent>
//           </React.Fragment>
//         )}
//       </TransformWrapper>
//     </div>
//   )
// }

class Ai extends Component {
    state = {};
    componentDidMount() {
        window.scrollTo(0, 0);
        console.log(this.props);
        // this.props.hideNavBar()
    }
    render() {
        switch (this.props.match.path) {
            case "/work/decoded/elaine":
                url =
                    "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/elaine.jpg";
                name = "Elaine";
                break;
            case "/work/decoded/juliet":
                url =
                    "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/juliet.jpg";
                name = "Juliet";
                break;
            case "/work/decoded/cora":
                url =
                    "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/cora.jpg";
                name = "Cora";
                break;
            case "/work/decoded/faith":
                url =
                    "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/faith.jpg";
                name = "Faith";
                break;
            case "/work/decoded/bella":
                url =
                    "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/bella.jpg";
                name = "Bella";
                break;
            case "/work/decoded/jasmine":
                url =
                    "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/jasmine.jpg";
                name = "Jasmine";
                break;
            case "/work/decoded/jada":
                url =
                    "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/jada.jpg";
                name = "Jada";
                break;
            case "/work/decoded/davina":
                url =
                    "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/davina.jpg";
                name = "Davina";
                break;
            case "/work/decoded/Tyra2045":
                smallPortraitSrc =
                    "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_aa.jpg";
                largePortraitSrc =
                    "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_aa_zoom.png";
                name = "Tyra, 2045";
                break;
            case "/work/decoded/Lydia2045":
                smallPortraitSrc =
                    "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_d.png";
                largePortraitSrc =
                    "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_d_hi.png";
                name = "Lydia, 2045";
                break;
            case "/work/decoded/Faith2045":
                smallPortraitSrc =
                    "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_f.jpg";
                largePortraitSrc =
                    "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_f_hi.jpg";
                name = "Faith, 2045";
                break;
            case "/work/decoded/Elaine2045":
                smallPortraitSrc =
                    "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_e.png";
                largePortraitSrc =
                    "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_e_hi.png";
                name = "Elaine, 2045";
                break;
            case "/work/decoded/Yuja2045":
                smallPortraitSrc =
                    "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_b.png";
                largePortraitSrc =
                    "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_b_hi.png";
                name = "Yuja, 2045";
                break;
            case "/work/decoded/georgia":
                url =
                    "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/georgia.jpg";
                name = "Georgia";
                break;
            case "/work/decoded/willow":
                url =
                    "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/willow.jpg";
                name = "Willow";
                break;
            default:
                url =
                    "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/elaine.jpg";
                name = "Elaine";
                break;
        }
        return (
            <div className="individual_ai_page">
                <div className="arrow_wrapper">
                    <Link to="/work/decoded" style={{ textDecoration: "none" }}>
                        <div className="arrow_container">
                            <i className="fas fa-chevron-left"></i>
                        </div>
                    </Link>
                </div>
                <TransformWrapper>
                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                        <React.Fragment>
                            <div className="tools">
                                <button onClick={zoomIn}>+</button>
                                <button onClick={zoomOut}>-</button>
                            </div>
                            <TransformComponent>
                                <img
                                    className="full_ai_img"
                                    src={largePortraitSrc}
                                    alt="test"
                                />
                            </TransformComponent>
                        </React.Fragment>
                    )}
                </TransformWrapper>
            </div>
        );
    }
}

export default Ai;
