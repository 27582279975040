import React, { Component, useState, useEffect } from "react"

import "./About.css"

const About = (props) => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    document.title = "Daniel Wallace"
    props.setAboutToActive()
    const timeout = setTimeout(() => {
      setLoading({ loading: true })
    }, 200)
    return () => {
      clearTimeout(timeout)
      props.setAboutToInactive()
    }
  }, [])
  return (
    <div className="about_page">
      <div className={loading ? "bio fading-in" : "bio"}>
        <p>
          Daniel Wallace is the alter ego of Daniel Nadler, who is an
          award-winning poet, artificial intelligence innovator, and visual
          artist.
        </p>
        <p>
          Nadler’s debut collection of poetry, <i>Lacunae</i> (2016) was
          published by Farrar, Straus and Giroux and was named a Best Book of
          the Year by NPR. In 2018 Nadler was elected to the board of directors
          of the Academy of American Poets, becoming the youngest person ever to
          be elected to the Academy's Board in its 85-year history.
        </p>
        <p>
          Nadler, who received his PhD from Harvard, founded the most valuable
          artificial intelligence company ever acquired, Kensho Technologies.
          Kensho's first investor was Google. In 2017, at Davos, Kensho was
          named by the World Economic Forum as "one of most innovative and
          impactful technology companies in the world". In 2018 Kensho became,
          according to <i>Forbes</i>, the most valuable privately owned
          artificial intelligence company in history when it was acquired by S&P
          Global for $700 million.
        </p>
        <p>
          As a visual artist, Nadler/Wallace works in digital painting,
          photography, video, text, sculpture, and performance. Wallace’s
          process involves working with generative artificial intelligence
          algorithms, physical world capture methods (including photography and
          motion capture), and traditional digital painting and digital
          sculpting techniques, bridging surrealism, photorealism, CGI, and
          hyperrealism. Wallace juxtaposes both new and classical techniques
          that have never been seen together before in order to produce new
          relationships and new visual realities.
        </p>
        <p>
          In 2020, he was invited to serve on the Digital Art Committee of the
          Whitney Museum, where he continues to play an active leadership role.
          In 2021 he was elected to the Board of Directors of the Museum of
          Modern Art (MoMA) PS1.
        </p>
      </div>
    </div>
  )
}

export default About
