import React, { Component, useState, useEffect } from "react"
import { Link } from "react-router-dom"

import "./DigitalHumanoids.css"

// const DigitalHumanoids = () => {
//   const [mobile, setMobile] = useState(false)
//   const [loading, setLoading] = useState(false)

//   useEffect(() => {
//     if (window.innerWidth < 500) {
//       setMobile({ mobile: true })
//       console.log("mobile", mobile)
//     } else {
//       setMobile({ mobile: false })
//       console.log("desktop", mobile)
//     }
//     return
//   }, [])

//   useEffect(() => {
//     setTimeout(() => {
//       setLoading({ loading: true })
//     }, 200)
//   })
//   return (
//     <div
//       className={
//         loading ? "digital_humanoids_page loading" : "digital_humanoids_page"
//       }
//     >
//       <div className="in_focus dh">in focus: digital humanoids</div>
//       <div className="in_focus_redirect_prompt">
//         click on a portrait thumbnail to see more detail
//       </div>
//       <div className="ai_gallery_wrap">
//         <div className="ai_gallery_container">
//           <div className="ai_img_container">
//             <Link to={mobile ? "decoded/mobile/Tyra" : "decoded/Tyra"}>
//               <img
//                 src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_aa.jpg"
//                 alt=""
//               />
//             </Link>
//             <Link
//               to={!mobile ? "decoded/Tyra" : "decoded/mobile/Tyra2045"}
//               style={{ textDecoration: "none", color: "grey" }}
//             >
//               <div className="ai_img_caption">Tyra, 2045</div>
//             </Link>
//           </div>
//           <div className="ai_img_container">
//             <Link
//               to={!mobile ? "decoded/Faith2045" : "decoded/mobile/Faith2045"}
//             >
//               <img
//                 src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_f.jpg"
//                 alt=""
//               />
//             </Link>
//             <Link
//               to={!mobile ? "decoded/Faith2045" : "decoded/mobile/Faith2045"}
//               style={{ textDecoration: "none", color: "grey" }}
//             >
//               <div className="ai_img_caption">Faith, 2045</div>
//             </Link>
//           </div>
//           <div className="ai_img_container">
//             <Link
//               to={!mobile ? "decoded/Elaine2045" : "decoded/mobile/Elaine2045"}
//             >
//               <img
//                 src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_e.png"
//                 alt=""
//               />
//             </Link>
//             <Link
//               to={!mobile ? "decoded/Elaine2045" : "decoded/mobile/Elaine2045"}
//               style={{ textDecoration: "none", color: "grey" }}
//             >
//               <div className="ai_img_caption">Elaine, 2045</div>
//             </Link>
//           </div>
//           <div className="ai_img_container">
//             <Link
//               to={!mobile ? "decoded/Lydia2045" : "decoded/mobile/Lydia2045"}
//             >
//               <img
//                 src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_d.png"
//                 alt=""
//               />
//             </Link>
//             <Link
//               to={!mobile ? "decoded/Lydia2045" : "decoded/mobile/Lydia2045"}
//               style={{ textDecoration: "none", color: "grey" }}
//             >
//               <div className="ai_img_caption">Lydia, 2045</div>
//             </Link>
//           </div>

//           <div className="ai_img_container">
//             <Link to={!mobile ? "decoded/Yuja2045" : "decoded/mobile/Yuja2045"}>
//               <img
//                 src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_b.png"
//                 alt=""
//               />
//             </Link>
//             <Link
//               to={!mobile ? "decoded/Yuja2045" : "decoded/mobile/Yuja2045"}
//               style={{ textDecoration: "none", color: "grey" }}
//             >
//               <div className="ai_img_caption">Yuja, 2049</div>
//             </Link>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default DigitalHumanoids

export default class DigitalHumanoids extends Component {
  state = { mobile: false, loading: false }
  componentDidMount() {
    document.title = "Daniel Wallace | Digital Humanoids"
    setTimeout(() => {
      this.setState({ loading: true })
    }, 200)
    if (window.innerWidth < 500) {
      this.setState({ mobile: true })
    } else {
      this.setState({ mobile: false })
    }
  }

  render() {
    return (
      <div
        className={
          this.state.loading
            ? "digital_humanoids_page loading"
            : "digital_humanoids_page"
        }
      >
        <div className="in_focus_wrapper">
          <div className="in_focus dh">hyperrealistic digital paintings</div>
        </div>
        <div className="in_focus_redirect_prompt">
          click on a portrait thumbnail to see more detail
        </div>
        <div className="ai_gallery_wrap hyperrealistic">
          <div className="ai_gallery_container hyperrealistic">
            <div className="ai_img_container">
              <Link
                to={
                  this.state.mobile
                    ? "hyperrealistic_digital_paintings/mobile/Tyra"
                    : "hyperrealistic_digital_paintings/Tyra"
                }
              >
                <img
                  src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_aa.jpg"
                  alt=""
                />
              </Link>
              <Link
                to={
                  !this.state.mobile
                    ? "hyperrealistic_digital_paintings/Tyra"
                    : "hyperrealistic_digital_paintings/mobile/Tyra"
                }
                style={{
                  textDecoration: "none",
                  color: "grey",
                }}
              >
                <div className="ai_img_caption">Tyra </div>
              </Link>
            </div>

            <div className="ai_img_container">
              <Link
                to={
                  !this.state.mobile
                    ? "hyperrealistic_digital_paintings/Lydia"
                    : "hyperrealistic_digital_paintings/mobile/Lydia"
                }
              >
                <img
                  src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_d.png"
                  alt=""
                />
              </Link>
              <Link
                to={
                  !this.state.mobile
                    ? "hyperrealistic_digital_paintings/Lydia"
                    : "hyperrealistic_digital_paintings/mobile/Lydia"
                }
                style={{
                  textDecoration: "none",
                  color: "grey",
                }}
              >
                <div className="ai_img_caption">Lydia</div>
              </Link>
            </div>

            <div className="ai_img_container">
              <Link
                to={
                  !this.state.mobile
                    ? "hyperrealistic_digital_paintings/Yuja"
                    : "hyperrealistic_digital_paintings/mobile/Yuja"
                }
              >
                <img
                  src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_b.png"
                  alt=""
                />
              </Link>
              <Link
                to={!this.state.mobile ? "decoded/Yuja" : "decoded/mobile/Yuja"}
                style={{
                  textDecoration: "none",
                  color: "grey",
                }}
              >
                <div className="ai_img_caption">Yuja</div>
              </Link>
            </div>
            <div className="ai_img_container">
              <Link
                to={
                  !this.state.mobile
                    ? "hyperrealistic_digital_paintings/Faith"
                    : "hyperrealistic_digital_paintings/mobile/Faith"
                }
              >
                <img
                  src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_f.jpg"
                  alt=""
                />
              </Link>
              <Link
                to={
                  !this.state.mobile
                    ? "hyperrealistic_digital_paintings/Faith"
                    : "hyperrealistic_digital_paintings/mobile/Faith"
                }
                style={{
                  textDecoration: "none",
                  color: "grey",
                }}
              >
                <div className="ai_img_caption">Faith</div>
              </Link>
            </div>
            <div className="ai_img_container">
              <Link
                to={
                  !this.state.mobile
                    ? "hyperrealistic_digital_paintings/Elaine"
                    : "hyperrealistic_digital_paintings/mobile/Elaine"
                }
              >
                <img
                  src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_e.png"
                  alt=""
                />
              </Link>
              <Link
                to={
                  !this.state.mobile
                    ? "hyperrealistic_digital_paintings/Elaine"
                    : "hyperrealistic_digital_paintings/mobile/Elaine"
                }
                style={{
                  textDecoration: "none",
                  color: "grey",
                }}
              >
                <div className="ai_img_caption">Elaine</div>
              </Link>
            </div>
          </div>
        </div>
        <div className="hyperrealistic_caption_wrapper">
          <div className="hyperrealistic_caption">
            Mixed media: Digital paintings executed on top of a facial
            composition that was realized using generative artificial
            intelligence algorithms and CGI tools.
          </div>
        </div>
      </div>
    )
  }
}
