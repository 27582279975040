import React from "react"

import "./NewsPopup.css"

const NewsPopup = (props) => {
  if (!props.isPopupVisible) {
    return null
  }
  return (
    <div className="newspopup_wrapper" onClick={props.togglePopup}>
      <div className="newspopup_content">
        <img
          className="news_collage"
          src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dna.png"
          alt=""
        />
      </div>
    </div>
  )
}

export default NewsPopup
