import React, { Component } from "react"
import { BrowserRouter as Router, Route } from "react-router-dom"

import Nav from "../src/components/nav/Nav"
import DropdownNav from "../src/components/nav/dropdown_nav/DropdownNav"
import Home from "../src/pages/home/Home"
import SelectedWork from "./pages/selected_work/SelectedWork"
// import Test from "./pages/selected_work/Test"
import Decoded from "./pages/decoded/Decoded"
import Humanoids from "./pages/humanoids/Humanoids"
import Classification from "./pages/classification/Classification"
import Sculpture from "./pages/sculpture/Sculpture"
import NeonBrutalism from "./pages/neon_brutalism/NeonBrutalism"
import DigitalHumanoids from "./pages/digital_humanoids/DigitalHumanoids"
import Eulogy from "./pages/eulogy/Eulogy"
import Simulacrum from "./pages/simulacrum/Simulacrum"
import Ai from "./pages/artwork/ai/Ai"
import Tyra from "./pages/full_page_ai/Tyra"
import TyraMobile from "./pages/full_page_ai/TyraMobile"
import Lydia from "./pages/full_page_ai/Lydia"
import LydiaMobile from "./pages/full_page_ai/LydiaMobile"
import Faith from "./pages/full_page_ai/Faith"
import FaithMobile from "./pages/full_page_ai/FaithMobile"
import Elaine from "./pages/full_page_ai/Elaine"
import ElaineMobile from "./pages/full_page_ai/ElaineMobile"
import Yuja from "./pages/full_page_ai/Yuja"
import YujaMobile from "./pages/full_page_ai/YujaMobile"
import Human from "./pages/artwork/human/Human"
import About from "../src/pages/about/About"
import Contact from "../src/pages/contact/Contact"
import Terms from "../src/pages/terms_and_conditions/Terms"
import PrivacyPolicy from "../src/pages/privacy_policy/PrivacyPolicy"

import "./App.css"

export default class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      is_about_active: false,
      is_work_active: false,
      is_contact_active: false,
      are_links_visible: true,
      source: "",
      isNavColorGrey: false,
      isNavHidden: false,
    }
  }
  setAboutToActive = () => {
    this.setState({ is_about_active: true })
  }
  setAboutToInactive = () => {
    this.setState({ is_about_active: false })
  }
  setWorkToActive = () => {
    this.setState({ is_work_active: true })
  }
  setWorkToInactive = () => {
    this.setState({ is_work_active: false })
  }
  setContactToActive = () => {
    this.setState({ is_contact_active: true })
  }
  setContactToInactive = () => {
    this.setState({ is_contact_active: false })
  }

  setNavColorToGrey = () => {
    this.setState({ isNavColorGrey: !this.state.isNavColorGrey })
  }
  hideNavBar = () => {
    this.setState({ isNavHidden: !this.state.isNavHidden })
  }
  render() {
    return (
      <div className={this.state.isNavHidden ? "App no_padding" : "App"}>
        <Router>
          <Nav
            are_links_visible={`${this.state.are_links_visible}`}
            isNavColorGrey={this.state.isNavColorGrey}
            isNavHidden={this.state.isNavHidden}
          />
          <DropdownNav
            is_about_active={this.state.is_about_active}
            is_work_active={this.state.is_work_active}
            is_contact_active={this.state.is_contact_active}
            isNavColorGrey={this.state.isNavColorGrey}
            isNavHidden={this.state.isNavHidden}
          />
          <Route
            exact
            path="/"
            render={() => <Home hideNavBar={this.hideNavBar} />}
          />
          <Route
            exact
            path="/daniel_wallace"
            render={() => (
              <About
                setAboutToActive={this.setAboutToActive}
                setAboutToInactive={this.setAboutToInactive}
              />
            )}
          />
          <Route
            exact
            path="/work"
            render={() => (
              <SelectedWork
                setWorkToActive={this.setWorkToActive}
                setWorkToInactive={this.setWorkToInactive}
              />
            )}
          />
          <Route
            exact
            path="/work/decoded"
            render={() => (
              <Decoded setNavColorToGrey={this.setNavColorToGrey} />
            )}
          />
          <Route exact path="/work/decoded/marie" component={Human} />
          <Route
            exact
            path="/work/decoded/bella"
            render={() => <Ai hideNavBar={this.hideNavBar} />}
          />
          <Route
            exact
            path="/work/decoded/Tyra"
            render={() => (
              <Tyra hideNavBar={this.hideNavBar} to={"/work/decoded"} />
            )}
          />
          <Route
            exact
            path="/work/decoded/mobile/Tyra"
            render={() => (
              <TyraMobile hideNavBar={this.hideNavBar} to={"/work/decoded"} />
            )}
          />
          <Route
            exact
            path="/work/decoded/Lydia"
            render={() => (
              <Lydia hideNavBar={this.hideNavBar} to={"/work/decoded"} />
            )}
          />
          <Route
            exact
            path="/work/decoded/mobile/Lydia"
            render={() => (
              <LydiaMobile hideNavBar={this.hideNavBar} to={"/work/decoded"} />
            )}
          />
          <Route
            exact
            path="/work/decoded/Faith"
            render={() => (
              <Faith hideNavBar={this.hideNavBar} to={"/work/decoded"} />
            )}
          />
          <Route
            exact
            path="/work/decoded/mobile/Faith"
            render={() => (
              <FaithMobile hideNavBar={this.hideNavBar} to={"/work/decoded"} />
            )}
          />
          <Route
            exact
            path="/work/decoded/Elaine"
            render={() => (
              <Elaine hideNavBar={this.hideNavBar} to={"/work/decoded"} />
            )}
          />
          <Route
            exact
            path="/work/decoded/mobile/Elaine"
            render={() => (
              <ElaineMobile hideNavBar={this.hideNavBar} to={"/work/decoded"} />
            )}
          />
          <Route
            exact
            path="/work/decoded/Yuja"
            render={() => (
              <Yuja hideNavBar={this.hideNavBar} to={"/work/decoded"} />
            )}
          />
          <Route
            exact
            path="/work/decoded/mobile/Yuja"
            render={() => (
              <YujaMobile hideNavBar={this.hideNavBar} to={"/work/decoded"} />
            )}
          />
          <Route
            exact
            path="/work/humanoids"
            render={() => (
              <Humanoids
                setWorkToActive={this.setWorkToActive}
                setWorkToInactive={this.setWorkToInactive}
              />
            )}
          />
          <Route exact path="/work/classification" component={Classification} />
          <Route
            exact
            path="/work/classification/julia"
            component={Classification}
          />
          <Route
            exact
            path="/work/classification/lisa"
            component={Classification}
          />
          <Route
            exact
            path="/work/classification/marie-louise"
            component={Classification}
          />
          <Route
            exact
            path="/work/classification/bri"
            component={Classification}
          />
          <Route
            exact
            path="/work/classification/kitty"
            component={Classification}
          />
          <Route
            exact
            path="/work/sculpture"
            render={() => <Sculpture hideNavBar={this.hideNavBar} />}
          />
          <Route
            exact
            path="/work/alice_is_living_in_a_simulation"
            component={NeonBrutalism}
          />
          <Route
            exact
            path="/work/hyperrealistic_digital_paintings"
            component={DigitalHumanoids}
          />
          <Route
            exact
            path="/work/hyperrealistic_digital_paintings/Tyra"
            render={() => (
              <Tyra
                hideNavBar={this.hideNavBar}
                to={"/work/hyperrealistic_digital_paintings"}
              />
            )}
          />
          <Route
            exact
            path="/work/hyperrealistic_digital_paintings/mobile/Tyra"
            render={() => (
              <TyraMobile
                hideNavBar={this.hideNavBar}
                to={"/work/hyperrealistic_digital_paintings"}
              />
            )}
          />
          <Route
            exact
            path="/work/hyperrealistic_digital_paintings/Lydia"
            render={() => (
              <Lydia
                hideNavBar={this.hideNavBar}
                to={"/work/hyperrealistic_digital_paintings"}
              />
            )}
          />
          <Route
            exact
            path="/work/hyperrealistic_digital_paintings/mobile/Lydia"
            render={() => (
              <LydiaMobile
                hideNavBar={this.hideNavBar}
                to={"/work/hyperrealistic_digital_paintings"}
              />
            )}
          />
          <Route
            exact
            path="/work/hyperrealistic_digital_paintings/Faith"
            render={() => (
              <Faith
                hideNavBar={this.hideNavBar}
                to={"/work/hyperrealistic_digital_paintings"}
              />
            )}
          />
          <Route
            exact
            path="/work/hyperrealistic_digital_paintings/mobile/Faith"
            render={() => (
              <FaithMobile
                hideNavBar={this.hideNavBar}
                to={"/work/hyperrealistic_digital_paintings"}
              />
            )}
          />
          <Route
            exact
            path="/work/hyperrealistic_digital_paintings/Elaine"
            render={() => (
              <Elaine
                hideNavBar={this.hideNavBar}
                to={"/work/hyperrealistic_digital_paintings"}
              />
            )}
          />
          <Route
            exact
            path="/work/hyperrealistic_digital_paintings/mobile/Elaine"
            render={() => (
              <ElaineMobile
                hideNavBar={this.hideNavBar}
                to={"/work/hyperrealistic_digital_paintings"}
              />
            )}
          />
          <Route
            exact
            path="/work/hyperrealistic_digital_paintings/Yuja"
            render={() => (
              <Yuja
                hideNavBar={this.hideNavBar}
                to={"/work/hyperrealistic_digital_paintings"}
              />
            )}
          />
          <Route
            exact
            path="/work/hyperrealistic_digital_paintings/mobile/Yuja"
            render={() => (
              <YujaMobile
                hideNavBar={this.hideNavBar}
                to={"/work/hyperrealistic_digital_paintings"}
              />
            )}
          />
          <Route exact path="/work/eulogy" component={Eulogy} />
          <Route
            exact
            path="/work/the_simulacrum_is_real"
            render={(props) => (
              <Simulacrum {...props} hideNavBar={this.hideNavBar} />
            )}
          />
          <Route
            exact
            path="/work/the_simulacrum_is_real/corridor_1"
            render={(props) => (
              <Simulacrum {...props} hideNavBar={this.hideNavBar} />
            )}
          />
          <Route
            exact
            path="/work/the_simulacrum_is_real/corridor_2"
            render={(props) => (
              <Simulacrum {...props} hideNavBar={this.hideNavBar} />
            )}
          />
          <Route
            exact
            path="/work/the_simulacrum_is_real/Alice_3"
            render={(props) => (
              <Simulacrum {...props} hideNavBar={this.hideNavBar} />
            )}
          />
          <Route
            exact
            path="/work/the_simulacrum_is_real/Alice_4"
            render={(props) => (
              <Simulacrum {...props} hideNavBar={this.hideNavBar} />
            )}
          />
          <Route
            exact
            path="/work/the_simulacrum_is_real/Alice_5"
            render={(props) => (
              <Simulacrum {...props} hideNavBar={this.hideNavBar} />
            )}
          />
          <Route exact path="/work/decoded/faith" component={Ai} />
          <Route exact path="/work/decoded/cora" component={Ai} />
          <Route exact path="/work/decoded/valou" component={Human} />
          <Route exact path="/work/decoded/jasmine" component={Ai} />
          <Route exact path="/work/decoded/jada" component={Ai} />
          <Route exact path="/work/decoded/davina" component={Ai} />
          <Route exact path="/work/decoded/tyra" component={Ai} />
          <Route exact path="/work/decoded/lisa" component={Human} />
          <Route exact path="/work/decoded/georgia" component={Ai} />
          <Route exact path="/work/decoded/willow" component={Ai} />
          <Route
            exact
            path="/contact"
            render={() => (
              <Contact
                setContactToActive={this.setContactToActive}
                setContactToInactive={this.setContactToInactive}
              />
            )}
          />
          <Route exact path="/terms_and_conditions" component={Terms} />
          <Route exact path="/privacy_policy" component={PrivacyPolicy} />
        </Router>
      </div>
    )
  }
}

// export default App;
