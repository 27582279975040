import React, { Component } from "react"
import API_BASE_URL from "../../config"
import { Redirect } from "react-router-dom"

import "./Contact.css"

export default class Contact extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      not_allowed: true,
      firstName: "",
      lastName: "",
      email: "",
      subject: "",
      text: "",
      modalVisible: false,
      redirect: false,
    }
  }
  componentDidMount() {
    console.log(this.state.modalVisible, this.props)
    document.title = "Daniel Wallace | Contact"
    this.setState({ modalVisible: false })
    setTimeout(() => {
      this.setState({ loading: true })
    }, 300)
    this.props.setContactToActive()
  }

  handleChange = (e) => {
    const { name, value } = e.target
    this.setState({ [name]: value })
    if (
      !this.state.text ||
      !this.state.email ||
      !this.state.firstName ||
      !this.state.lastName ||
      !this.state.subject
    ) {
      this.setState({ not_allowed: true })
    } else {
      this.setState({ not_allowed: false })
    }
  }

  sendMessage = (e) => {
    e.preventDefault()
    const { firstName, lastName, subject, email, text } = this.state

    fetch(`${API_BASE_URL}/message`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ firstName, lastName, subject, email, text }),
    })
      .then((res) => res.json)
      .catch((err) => console.log(err))

    this.setState({
      firstName: "",
      lastName: "",
      subject: "",
      email: "",
      text: "",
      loading: false,
    })
    setTimeout(() => {
      this.setState({ modalVisible: true, loading: false })
    }, 1200)
    setTimeout(() => {
      this.setState({ redirect: true })
    }, 2000)
  }

  componentWillUnmount() {
    this.props.setContactToInactive()
    this.setState({ modalVisible: false, loading: false })
  }
  render() {
    if (this.state.redirect) {
      return <Redirect push to="/" />
    }
    return (
      <div className="contact_page">
        <div
          className={
            this.state.modalVisible ? "form_modal" : "form_modal hidden"
          }
        >
          Message sent!
        </div>
        <div
          className={
            this.state.loading
              ? "contact_detail_container fading-in"
              : "contact_detail_container"
          }
        >
          <form
            action="submit"
            className="contact_form"
            onSubmit={(e) => this.sendMessage(e)}
          >
            <label htmlFor="" className="name_lbl">
              Name
            </label>
            <div className="name">
              <div className="f_name">
                <input
                  type="text"
                  className="f_name_i"
                  name="firstName"
                  value={this.state.firstName}
                  onChange={this.handleChange}
                />
                <label htmlFor="" className="">
                  First Name*
                </label>
              </div>
              <div className="l_name">
                <input
                  type="text"
                  className="l_name_i"
                  name="lastName"
                  value={this.state.lastName}
                  onChange={this.handleChange}
                />
                <label htmlFor="" className="">
                  Last Name*
                </label>
              </div>
            </div>
            <label htmlFor="" className="">
              Email Address*
            </label>
            <input
              type="text"
              className="e_address"
              name="email"
              value={this.state.email}
              onChange={this.handleChange}
            />
            <label htmlFor="" className="">
              Subject*
            </label>
            <input
              type="text"
              className="subject"
              name="subject"
              value={this.state.subject}
              onChange={this.handleChange}
            />
            <label htmlFor="" className="">
              Message*
            </label>
            <textarea
              type="text"
              className="message"
              name="text"
              value={this.state.text}
              onChange={this.handleChange}
            />
            <button
              className={
                this.state.not_allowed
                  ? "submit_btn not_allowed"
                  : "submit_btn "
              }
              disabled={
                !this.state.text ||
                !this.state.email ||
                !this.state.firstName ||
                !this.state.lastName ||
                !this.state.subject
              }
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    )
  }
}
