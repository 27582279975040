import React, { useState, useEffect } from "react"
import ReactImageMagnify from "react-image-magnify"

// import "../Artwork.css"
import "./Portrait.css"

const Portrait = (props) => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setTimeout(() => setLoading(true), 100)
  })
  return (
    <div
      className={loading ? "portraits_wrapper fading-in" : "portraits_wrapper"}
      style={{ top: "500px" }}
    >
      <div className="portrait_container">
        <div className="original_img">
          <ReactImageMagnify
            {...{
              smallImage: {
                alt: "dw",
                src: props.smallPortraitSrc,
                isFluidWidth: true,
                // width: props.portraitWidth,
                // height: props.portraitHeight,
              },
              largeImage: {
                src: props.largePortraitSrc,
                width: props.magnifiedWidth,
                height: props.magnifiedHeight,
              },
              enlargedImagePosition: "beside",
            }}
          />
          <div className="portrait_desc">
            {props.status === `ai` ? (
              <div className="portrait_caption_original">
                <div className="portrait_caption">
                  {props.name ? `"${props.name}". ` : ""}This person does not
                  exist. She was created by Wallace using a combination of
                  generative artificial intelligence algorithms and digital
                  painting.
                </div>
              </div>
            ) : (
              <div className="portrait_caption_original">
                <div className="portrait_caption">
                  Human. Shot at Wallace’s studio, {props.year}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="magnified_img">
          <div className="portrait_caption_mock">
            This person does not exist. She was created by Wallace using a
            combination of generative artificial intelligence algorithms and
            digital painting.
          </div>
        </div>
      </div>
    </div>
  )
}

export default Portrait
