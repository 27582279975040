import React from "react"
import DropdownNav from "../../components/nav/dropdown_nav/DropdownNav"

import "./PrivacyPolicy.css"

const PrivacyPolicy = () => {
  return (
    <div className="privacy_policy_page">
      <DropdownNav />
      <div className="privacy_policy_section">
        <h1>privacy policy</h1>
        <p className="privacy_policy_title">
          1. introduction and general terms
        </p>
        <p>
          Daniel Nadler Limited, a company registered under company number
          077799933 (“Daniel Nadler”, “we”, “us” or “our” in this policy), forms
          part of an international commercial gallery representing artists
          worldwide, and operating in New York, London and Paris (“Services”).
        </p>
        <p>
          If you have any questions or comments about this privacy policy please
          contact us via:
        </p>
        <p>
          Telephone: United States: +1 212 727 2070 United Kingdom: +44 203 538
          3165 Paris: +33 1 43 56 32 95
        </p>
        <p>Email: information@danielnadler.com</p>
        <p>
          Daniel Nadler is committed to protecting and respecting your privacy.
          This policy explains the basis on which personal information we
          collect from you will be processed by us. Where we decide the purpose
          or means for which Personal Data you supply through these Services is
          processed, we are the “data controller” for the General Data
          Protection Regulation 2016/679.
        </p>
      </div>
    </div>
  )
}

export default PrivacyPolicy
