import React from "react"
// import { useTransition, animated } from "react-spring";
// import { Link } from "react-router-dom";

import "./Footer.css"

const Footer = () => {
  return (
    <div className="footer">
      <div className="social_media_section">
        <a
          href="//instagram.com/thewallacecorporation/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none", color: "black" }}
        >
          <div className="">
            <i className="fab fa-instagram"></i>
            <span className="social_media_name">Instagram</span>
          </div>
        </a>
      </div>
    </div>
  )
}

export default Footer
