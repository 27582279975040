import React from "react"

import "./Artwork.css"

const Artwork = (props) => {
  return (
    <div className={props.portraitContainerClass} onClick={props.onClick}>
      <img src={props.src} alt="" />
      <div className={props.captionClass}>{props.homepageCaption}</div>
    </div>
  )
}

export default Artwork
