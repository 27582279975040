import React, { Component, useState, useEffect, useRef } from "react"
import ClassificationPaintings from "../../components/classification_paintings/ClassificationPaintings"
import Carousel from "../../components/carousel/classification_carousel/Carousel"
import { Player, BigPlayButton, ControlBar } from "video-react"
import { Element, scroller } from "react-scroll"

import "video-react/dist/video-react.css"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

import "./Classification.css"

export default class Classification extends Component {
  state = { loading: false, loaderVisible: false }
  componentDidMount() {
    document.title = "Daniel Wallace | Classification"

    setTimeout(() => {
      this.setState({ loading: true })
      switch (this.props.location.pathname) {
        case "/work/classification/julia":
          scroller.scrollTo("julia", {
            duration: 900,
            delay: 100,
            smooth: true,
          })
          break
        case "/work/classification/lisa":
          scroller.scrollTo("lisa", {
            duration: 900,
            delay: 100,
            smooth: true,
          })
          break
        case "/work/classification/marie-louise":
          scroller.scrollTo("marie-louise", {
            duration: 900,
            delay: 100,
            smooth: true,
          })
          break
        case "/work/classification/bri":
          scroller.scrollTo("bri", {
            duration: 900,
            delay: 100,
            smooth: true,
          })
          break
        case "/work/classification/kitty":
          scroller.scrollTo("kitty", {
            duration: 900,
            delay: 100,
            smooth: true,
          })
          break
      }
    }, 300)
  }

  showLoader = () => {
    this.setState({ loaderVisible: true })
  }
  onLoad = () => {
    this.setState({ loaderVisible: false })
  }
  render() {
    return (
      <div className="classification_page">
        <div
          className={
            this.state.loading
              ? "classification_container fading-in"
              : "classification_container"
          }
        >
          <div className="classification_description">
            <p className="classification">classification</p>
            <br />
            <p>
              Wallace programmed his own proprietary emotion recognition and
              classification A.I. using the same state-of-the art technology
              used by governments and major corporations. He then curated
              moments from the history of silent cinema when human performers,
              ‘speaking’ only with their emotions, reached sublime states of
              interiority that the common sense viewer instantly recognizes as
              resisting programmatic classification. Running the latter
              performances through the former A.I. produces an alchemy that is
              at once arresting and alarming—an alchemy which should make us
              stop and reflect on the implications of the increasing ubiquity of
              such technology in our society.
            </p>
          </div>
        </div>
        <div
          className={
            this.state.loading ? "player_wrapper fading-in" : "player_wrapper"
          }
        >
          <div className="passion_title_container">
            <p>passion</p>
          </div>
          <div className="player_container">
            <Player
              ref={(player) => {
                this.player = player
              }}
              muted
              autoPlay
              aspectRatio="16:9"
              src={
                "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_psn.mp4"
              }
            >
              <BigPlayButton position="center" />
              <ControlBar autoHide={true} autoHideTime={500} />
            </Player>
          </div>
        </div>
        <div className="truth_in_advertising">
          <p className="truth_in_advertising_title">truth in advertising</p>
          <Element name="julia">
            <div className="truth_img">
              <img
                src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_tia_j.JPG"
                alt=""
              />
            </div>
          </Element>
          <div className="truth_in_advertising_desc">
            <div className="tia_desc">
              Influenced by the work of Barbara Kruger, Wallace took the emotion
              recognition and classification A.I. that he programmed using the
              same state-of-the art technology used by governments and major
              corporations, and applied it to images (captured by Wallace over
              multiple studio sessions) archetypal of those employed in the
              promotion of consumerism. One can easily imagine which brands,
              logos, and products these images would normally be used to endorse
              and validate. Instead, all such insignia are conspicuously absent:
              The feeling of incompletion of an act of consumerism, and the
              erasure of consumer culture. Then in a move of playful subversion,
              these missing and erased product logos and slogans are replaced by
              what in this context becomes a 'lie detector'-style analysis
              enabled by an emotion recognition and classification A.I.—a
              revelation into the emotional interiority of the human subject as
              she undergoes this consumerist performance. A rare,
              technologically-enabled illumination of the darker side of
              consumer culture and society’s collective desires.
            </div>
          </div>
          {/* <Element name="lisa">
            <div className="truth_img">
              <img
                src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_tia_l1.JPG"
                alt=""
              />
            </div>
          </Element>
          <Element name="marie-louise">
            <div className="truth_img">
              <img
                src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_tia_ml.JPG"
                alt=""
              />
            </div>
          </Element>
          <Element name="bri">
            <div className="truth_img">
              <img
                src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_tia_b2.JPG"
                alt=""
              />
            </div>
          </Element>
          <Element name="kitty">
            <div className="truth_img">
              <img
                src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_tia_k2.JPG"
                alt=""
              />
            </div>
          </Element> */}
        </div>
        <div className="tia_wild">
          truth in advertising: found in the wild & analyzed
        </div>
        <Carousel
          url1="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_tiafitw_kj.jpeg"
          url2="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_tiafitw_km.jpeg"
        />
        <div className="classification_paintings">
          <p>classification paintings</p>
          <div className="classification_p_zoom_instructions desktop">
            mouse over images to see details
          </div>
          <div className="classification_p_zoom_instructions mobile">
            press and hold over portraits to see details
          </div>
        </div>

        <ClassificationPaintings
          smallPortraitSrc="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_bg-2600.jpg"
          largePortraitSrc="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_bg_6K.jpg"
          showLoader={this.showLoader}
          onLoad={this.onLoad}
          loaderVisible={this.state.loaderVisible}
        />
        <div className="classification_painting_caption_wrapper">
          <div className="classification_painting_caption">
            “Blue Eyes, Green Eyes #3”, Captured at Wallace's Studio,
            2019-Ongoing
          </div>
        </div>
        <ClassificationPaintings
          smallPortraitSrc="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_rg_2600.jpg"
          largePortraitSrc="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_rg_7K.jpg"
          showLoader={this.showLoader}
          onLoad={this.onLoad}
          loaderVisible={this.state.loaderVisible}
        />
        <div className="classification_painting_caption_wrapper">
          <div className="classification_painting_caption">
            "Pink Lips", Captured at Wallace's Studio, 2019-Ongoing
          </div>
        </div>
      </div>
    )
  }
}

// const Classification = () => {
//   const [loading, setLoading] = useState(false)
//   const [loaderVisible, setLoaderVisible] = useState(false)
//   const player = useRef(null)

//   useEffect(() => {
//     showLoader(() => {
//       setLoaderVisible({ loaderVisible: true })
//     })
//     hideLoader()
//     onLoad()
//   }, [])

//   const showLoader = () => {
//     setLoaderVisible({ loaderVisible: true })
//     console.log("ok")
//   }
//   const hideLoader = () => {
//     setLoaderVisible({ loaderVisible: false })
//     console.log("ok")
//   }
//   const onLoad = () => {
//     setLoaderVisible({ loaderVisible: false })
//     console.log(loaderVisible)
//   }
//   useEffect(() => {
//     document.title = "Daniel Wallace | Classification"
//     const timeout = () => {
//       setTimeout(() => {
//         setLoading({ loading: true })
//       }, 300)
//     }
//     return () => timeout
//   }, [])

//   return (
//     <div className="classification_page">
//       <div
//         className={
//           loading
//             ? "classification_container fading-in"
//             : "classification_container"
//         }
//       >
//         <div className="classification_description">
//           <p className="classification">classification</p>
//           <br />
//           <p>
//             Wallace programmed his own proprietary emotion recognition and
//             classification A.I. using the same state-of-the art technology used
//             by governments and major corporations. He then curated moments from
//             the history of silent cinema when human performers, ‘speaking’ only
//             with their emotions, reached sublime states of interiority that the
//             common sense viewer instantly recognizes as resisting programmatic
//             classification. Running the latter performances through the former
//             A.I. produces an alchemy that is at once arresting and alarming—an
//             alchemy which should make us stop and reflect on the implications of
//             the increasing ubiquity of such technology in our society.
//           </p>
//         </div>
//       </div>
//       <div className="player_wrapper">
//         <div className="passion_title_container">
//           <p>passion</p>
//         </div>
//         <div className="player_container">
//           <Player
//             ref={player}
//             muted
//             autoPlay
//             aspectRatio="16:9"
//             src={
//               "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_psn.mp4"
//             }
//           >
//             <BigPlayButton position="center" />
//             <ControlBar autoHide={true} autoHideTime={500} />
//           </Player>
//         </div>
//       </div>
//       <div className="truth_in_advertising">
//         <p className="truth_in_advertising_title">truth in advertising</p>
//         <div className="truth_img">
//           <img
//             src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_tia_j.JPG"
//             alt=""
//           />
//         </div>
//         <div className="truth_in_advertising_desc">
//           <div className="tia_desc">
//             Influenced by the work of Barbara Kruger, Wallace took the emotion
//             recognition and classification A.I. that he programmed using the
//             same state-of-the art technology used by governments and major
//             corporations, and applied it to images (captured by Wallace over
//             multiple studio sessions) archetypal of those employed in the
//             promotion of consumerism. One can easily imagine which brands,
//             logos, and products these images would normally be used to endorse
//             and validate. Instead, all such insignia are conspicuously absent:
//             The feeling of incompletion of an act of consumerism, and the
//             erasure of consumer culture. Then in a move of playful subversion,
//             these missing and erased product logos and slogans are replaced by
//             what in this context becomes a 'lie detector'-style analysis enabled
//             by an emotion recognition and classification A.I.—a revelation into
//             the emotional interiority of the human subject as she undergoes this
//             consumerist performance. A rare, technologically-enabled
//             illumination of the darker side of consumer culture and society’s
//             collective desires.
//           </div>
//         </div>
//         <Carousel
//           url1="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_tia_l1.JPG"
//           url2="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_tia_l2.JPG"
//         />
//         <div className="truth_img">
//           <img
//             src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_tia_ml.JPG"
//             alt=""
//           />
//         </div>
//       </div>
//       <Carousel
//         url1="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_tia_b.JPG"
//         url2="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_tia_b2.JPG"
//       />
//       <Carousel
//         url1="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_tia_k2.JPG"
//         url2="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_tia_k1.JPG"
//       />
//       <div className="classification_paintings">
//         <p>classification paintings</p>
//         <div className="classification_p_zoom_instructions desktop">
//           mouse over images to see details
//         </div>
//         <div className="classification_p_zoom_instructions mobile">
//           press and hold over portraits to see details
//         </div>
//       </div>

//       <ClassificationPaintings
//         smallPortraitSrc="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_bg-2600.jpg"
//         largePortraitSrc="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_bg_6K.jpg"
//         showLoader={showLoader}
//         hideLoader={hideLoader}
//         onLoad={onLoad}
//         loaderVisible={loaderVisible}
//       />
//       <div className="classification_painting_caption_wrapper">
//         <div className="classification_painting_caption">
//           “Blue Eyes, Green Eyes #3”, Captured at Wallace's Studio, 2019-Ongoing
//         </div>
//       </div>
//       <ClassificationPaintings
//         smallPortraitSrc="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_rg_2600.jpg"
//         largePortraitSrc="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_rg_7K.jpg"
//         showLoader={showLoader}
//         hideLoader={hideLoader}
//         onLoad={onLoad}
//         loaderVisible={loaderVisible}
//       />
//       <div className="classification_painting_caption_wrapper">
//         <div className="classification_painting_caption">
//           "Pink Lips", Captured at Wallace's Studio, 2019-Ongoing
//         </div>
//       </div>
//     </div>
//   )
// }

// export default Classification
