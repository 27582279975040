import React, { useState, useEffect } from "react"
import ReactImageMagnify from "react-image-magnify"

// import "../Artwork.css"
import "./Diptych.css"

const Diptych = (props) => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setTimeout(() => setLoading(true), 100)
  })
  return (
    <div
      className={
        loading
          ? "humanoids_diptych_wrapper fading-in"
          : "humanoids_diptych_wrapper"
      }
      style={{ top: "500px" }}
    >
      <div className="humanoids_diptych_container">
        <div className="humanoids_portrait_l">
          <ReactImageMagnify
            {...{
              smallImage: {
                alt: "dw",
                src: props.smallPortraitSrcLeft,
                isFluidWidth: true,
                // width: props.portraitWidth,
                // height: props.portraitHeight,
              },
              largeImage: {
                src: props.largePortraitSrcLeft,
                width: props.magnifiedWidth,
                height: props.magnifiedHeight,
              },
              imageClassName: props.portraitClass,
              enlargedImagePosition: "over",
            }}
          />
        </div>
        <div className="humanoids_portrait_r">
          <ReactImageMagnify
            {...{
              smallImage: {
                alt: "dw",
                src: props.smallPortraitSrcRight,
                isFluidWidth: true,
                // width: props.portraitWidth,
                // height: props.portraitHeight,
              },
              largeImage: {
                src: props.largePortraitSrcRight,
                width: props.magnifiedWidth,
                height: props.magnifiedHeight,
              },
              imageClassName: props.portraitClass,
              enlargedImagePosition: "over",
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Diptych
