import React, { Component, useState, useEffect, useRef, createRef } from "react"
import { Element, scroller } from "react-scroll"
import Modal from "../../components/modal/Modal"

import Artwork from "../../components/artwork/Artwork"

import "./Simulacrum.css"

const Simulacrum = (props) => {
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [modalImgSrc, setModalImgSrc] = useState("")

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(true)
      switch (props.location.pathname) {
        case "/work/the_simulacrum_is_real/corridor_1":
          scroller.scrollTo("corridor_1", {
            duration: 500,
            delay: 100,
            smooth: true,
          })
          break
        case "/work/the_simulacrum_is_real/corridor_2":
          scroller.scrollTo("corridor_2", {
            duration: 500,
            delay: 100,
            smooth: true,
          })

          break
        case "/work/the_simulacrum_is_real/Alice_3":
          scroller.scrollTo("alice_3", {
            duration: 500,
            delay: 100,
            smooth: true,
          })
          break
        case "/work/the_simulacrum_is_real/Alice_4":
          scroller.scrollTo("alice_4", {
            duration: 500,
            delay: 100,
            smooth: true,
          })
          break
        case "/work/the_simulacrum_is_real/Alice_5":
          scroller.scrollTo("alice_5", {
            duration: 500,
            delay: 100,
            smooth: true,
          })
          break
        // default:
        //   "/work/the_simulacrum_is_real":
      }
    }, 200)
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  const openModal = () => {
    document.body.classList.add("modal-open")
    document.body.style.backgroundColor = "black"
    props.hideNavBar()
  }

  const closeModal = () => {
    document.body.classList.remove("modal-open")
    document.body.style.backgroundColor = "white"
    props.hideNavBar()
  }

  return (
    <div className={loading ? "simulacrum_page loading" : "simulacrum_page"}>
      <Modal
        modalImgSrc={modalImgSrc}
        modalClass="simulacrum_modal"
        imgContainerClass="simulacrum_modal_img_container"
        imgClass="simulacrum_modal_img"
        onClick={() => {
          setModalImgSrc("")
          setShow(false)
          closeModal()
        }}
        show={show}
      />
      {/* <div
        className={
          modalVisible ? "simulacrum_modal" : "simulacrum_modal hidden"
        }
        onClick={closeModal}
      >
        <div className="simulacrum_modal_content">
          <img className="simulacrum_modal_img" src={modalImgSrc} alt="" />
        </div>
      </div> */}
      <div className="simulacrum_container">
        <div className="simulacrum_header">the simulacrum is real</div>
        <Element name="corridor_1">
          <div className="simulacrum_artw_container corridor1">
            <img
              src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_tsir.jpg"
              srcSet="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_tsir.jpg 1400w, https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_tsir_3500.jpg 3500w"
              sizes="(max-width: 850px) 3500px, 1400px"
              alt="simulacrum"
            />
            <div className="simulacrum_artw_caption">
              "The Simulacrum is Real. Corridor #8979323846"
            </div>
          </div>
        </Element>
        <Artwork
          portraitContainerClass="simulacrum_artw_container mobile"
          src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_tsir.jpg"
          onClick={() => {
            setModalImgSrc(
              "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_tsir.jpg"
            )
            setShow(true)
            openModal()
          }}
        />
        <div className="simulacrum_artw_caption mobile">
          "The Simulacrum is Real. Corridor #8979323846"
        </div>
        <div className="simulacrum_txt_container">
          <p>
            "If we were able to take as the finest allegory of simulation the
            Borges tale where the cartographers of the Empire draw up a map so
            detailed that it ends up exactly covering the territory (but where,
            with the decline of the Empire this map becomes frayed and finally
            ruined, a few shreds still discernible in the deserts - the
            metaphysical beauty of this ruined abstraction, bearing witness to
            an imperial pride and rotting like a carcass, returning to the
            substance of the soil, rather as an aging double ends up being
            confused with the real thing), this fable would then have come full
            circle for us, and now has nothing but the discrete charm of
            second-order simulacra.
          </p>
          <p>
            Abstraction today is no longer that of the map, the double, the
            mirror or the concept. Simulation is no longer that of a territory,
            a referential being or a substance. It is the generation by models
            of a real without origin or reality: a hyperreal. The territory no
            longer precedes the map, nor survives it. Henceforth, it is the map
            that precedes the territory - precession of simulacra - it is the
            map that engenders the territory and if we were to revive the fable
            today, it would be the territory whose shreds are slowly rotting
            across the map. It is the real, and not the map, whose vestiges
            subsist here and there, in the deserts which are no longer those of
            the Empire, but our own. The desert of the real itself." — Jean
            Baudrillard
          </p>
        </div>
        <Element name="corridor_2">
          <div className="simulacrum_artw_container corridor2">
            <img
              src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_tsir2.jpg"
              srcSet="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_tsir2.jpg 1400w, https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_tsir2_3500.jpg 3500w"
              sizes="(max-width: 850px) 3500px, 1400px"
              alt="simulacrum2"
            />
            <div className="simulacrum_artw_caption">
              "The Simulacrum is Real. Corridor #2643383279"
            </div>
          </div>
        </Element>
        <Artwork
          portraitContainerClass="simulacrum_artw_container mobile"
          src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_tsir2.jpg"
          onClick={() => {
            setModalImgSrc(
              "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_tsir2.jpg"
            )
            setShow(true)
            openModal()
          }}
        />
        <div className="simulacrum_artw_caption mobile">
          "The Simulacrum is Real. Corridor #2643383279"
        </div>
        <div className="simulacrum_txt_container">
          People who have been raised in a virtual world are starting to believe
          they can really live in it.
        </div>
        <Element name="alice_3">
          <div className="simulacrum_artw_container alice">
            <img
              src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_sim_3.jpg"
              alt=""
            />
            <div className="simulacrum_artw_caption">
              "Alice is Living in a Simulation #3"
            </div>
          </div>
        </Element>
        <Artwork
          portraitContainerClass="simulacrum_artw_container mobile"
          src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_sim_3.jpg"
          onClick={() => {
            setModalImgSrc(
              "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_sim_3.jpg"
            )
            setShow(true)
            openModal()
          }}
        />
        <div className="simulacrum_artw_caption mobile">
          "Alice is Living in a Simulation #3"
        </div>
        <Element name="alice_4">
          <div className="simulacrum_artw_container">
            <img
              src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/alice_4.jpg"
              alt=""
            />
            <div className="simulacrum_artw_caption">
              "Alice is Living in a Simulation #4"
            </div>
          </div>
        </Element>
        <Artwork
          portraitContainerClass="simulacrum_artw_container mobile"
          src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/alice_4.jpg"
          onClick={() => {
            setModalImgSrc(
              "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/alice_4.jpg"
            )
            setShow(true)
            openModal()
          }}
        />
        <div className="simulacrum_artw_caption mobile">
          "Alice is Living in a Simulation #4"
        </div>
        <div className="simulacrum_txt_container">
          <p>
            <b>Process</b>: The humans in these works were captured by Wallace
            during real-world portrait sittings at Wallace’s studio; the
            landscapes are drawn from actual in-game exploration of actual game
            maps that real human users around the world have created and
            simulated; digital manipulation is used to dissolve the borders and
            boundaries between the starting ‘base realities’ of the biological
            human and the virtual landscape. Brought together this
            cross-sectional simultaneous perspective of multiple parallel
            realities superimposed on top of one another forges a hyper-reality,
            one that, perhaps—like the windowpanes in the composition—serves to
            reflect the broken and disjointed metaphysics of our own
            civilization’s stage of technological development.
          </p>{" "}
          <p>
            As a further part of the process, actual in-game maps are
            re-composed and further stylized by Wallace, with the in-game
            virtual buildings, virtual trees, and other virtual terrain features
            manually shrunken, enlarged, rotated, erased, and re-positioned in
            the composition, and in-game atmospheric conditions (sun, moon,
            stars, lighting) digitally manipulated and composed—all to transform
            elements taken from actual real-user virtual simulations into a
            painterly <i>mise-en-scène</i> of a human figure in a landscape of a
            brave new world.
          </p>
        </div>
        <Element name="alice_5">
          <div className="simulacrum_artw_container">
            <img
              src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/alice_5.jpg"
              alt=""
            />
            <div className="simulacrum_artw_caption">
              "Alice is Living in a Simulation #5"
            </div>
          </div>
        </Element>
        <Artwork
          portraitContainerClass="simulacrum_artw_container mobile"
          src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/alice_5.jpg"
          onClick={() => {
            setModalImgSrc(
              "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/alice_5.jpg"
            )
            setShow(true)
            openModal()
          }}
        />
        <div className="simulacrum_artw_caption mobile">
          "Alice is Living in a Simulation #5"
        </div>
        <div className="simulacrum_txt_container">
          <p>
            In conveying these originally mathematically precise and pixilated
            virtual landscapes <i>impressionistically</i>, through the
            manipulation of atmospheric elements and conditions, Wallace’s
            digitally-painted compositions recall the techniques of Turner and
            Monet. But more ontologically, in undertaking these painterly
            transformations of real-user virtual landscapes, Wallace creates the
            second-derivative of landscape virtualization, opening a new
            frontier in the long and rich art-historical tradition of landscape
            manipulation and abstraction that grows out from Constable & Turner,
            Hokusai & Hiroshige, and VvG & Matisse. In the works of these 19th
            and 20th Century painters (who were—intentionally or
            unintentionally— working to forge the ‘first derivative’ of
            landscape virtualization), ‘realism’ and the accurate visual reality
            of nature is intentionally lost, and the things-in-themselves are
            replaced by mere symbols of those things. But in Wallace’s works we
            encounter a new, <i>second-derivative</i> of landscape
            virtualization, where <i>symbols</i> of nature become the starting
            reality; the actual physical natural world (always ‘off-camera’ in
            these compositions) is in turn perceived (to the extent is is
            perceived or even considered at all) as perhaps the mere
            afterthought and simulacrum of these ‘on-camera’ pure virtual
            symbols of ‘nature,’ in relation to which physical nature is
            relegated to the status of—at best—corrupted imitation; upon going
            outside, a biological tree begins to remind the viewer of the
            virtual trees they have been immersed in, as opposed to the other
            way around. The virtual—posterior in linear time to physical
            nature—becomes the being of nature; symbols of nature become the
            things-in-themselves; our experience of reality is replaced with an
            entry into hyper-reality: an endless and infinite sequence of
            perpetual becoming without destination, of symbols with forgotten
            referents—and without any further need for corresponding analogs in
            physical-biological reality in order to have meaning; symbols which
            thus achieve transcendence to the invulnerable reality of the
            self-referential.
          </p>
          <p>
            {" "}
            Through these transformations Wallace thus recontextualizes
            real-world virtual landscapes and simulations as sinister symptoms
            of a world-historical metaphysical crisis (perhaps universal to this
            stage of technological civilization)…and as certainly more than
            initially meets the eye (of the human players that we have all
            become).
          </p>
          <p>
            {" "}
            Wallace conceived of these works as being in the tradition of not
            just abstract landscape painting, but in the tradition of the old
            religious paintings as well. These digital paintings, however, are
            about the religious faith of the future—the religious faith that our
            children’s children will grow up with. An extraordinary moment in
            the history of our species is upon us: The first moment in which the
            basic reality of nature is no longer our metaphysical starting point
            (a development which is in fact an inversion of the old religions,
            in which the denial of the reality of the apparent natural universe
            was at least the final step in a long chain of argumentation and
            theodicy, as opposed to the ontological starting point that it is
            becoming today).
          </p>
        </div>
      </div>
    </div>
  )
}

export default Simulacrum
