import React, { useState, useEffect } from "react"
import IndividualSculpture from "../../components/sculpture/IndividualSculpture"
import Modal from "../../components/modal/Modal"

import "./Sculpture.css"

const Sculpture = (props) => {
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [modalImgSrc, setModalImgSrc] = useState("")

  useEffect(() => {
    document.title = "Daniel Wallace | Sculpture"

    let timeout = setTimeout(() => {
      setLoading(true)
    }, 300)
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  const openModal = () => {
    document.body.classList.add("modal-open")
    setShow({ show: true })
    props.hideNavBar()
  }

  const closeModal = () => {
    document.body.classList.remove("modal-open")
    props.hideNavBar()
  }

  return (
    <div className={loading ? "sculpture_page loading" : "sculpture_page"}>
      <Modal
        modalImgSrc={modalImgSrc}
        modalClass="sculpture_modal"
        imgContainerClass="sculpture_modal_img_container"
        imgClass="sculpture_modal_img"
        onClick={() => {
          setShow(false)
          closeModal()
        }}
        show={show}
      />
      <div className="sculpture_container">
        <div className="sculpture_header">sculpture</div>
        <div className="sculpture_desktop">
          <IndividualSculpture src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_4055.jpg" />
          <IndividualSculpture src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_4057.jpg" />
          <IndividualSculpture src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_4053.jpg" />
          <IndividualSculpture src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_4059.jpg" />
        </div>
        <div className="sculpture_mobile">
          <IndividualSculpture
            src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_4055.jpg"
            onClick={() => {
              setModalImgSrc(
                "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_4055.jpg"
              )
              openModal()
            }}
          />
          <IndividualSculpture
            src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_4057.jpg"
            onClick={() => {
              setModalImgSrc(
                "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_4057.jpg"
              )
              openModal()
            }}
          />
          <IndividualSculpture
            src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_4053.jpg"
            onClick={() => {
              setModalImgSrc(
                "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_4053.jpg"
              )
              openModal()
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Sculpture
