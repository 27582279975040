import React, { useState, useEffect, useCallback } from "react"
import { useTransition, animated } from "react-spring"
import ReactImageMagnify from "react-image-magnify"

import "./Carousel.css"
const wc =
  "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_carou_wc_sl.jpg"
const ana =
  "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_h_carou_ana.jpg"
const ailias =
  "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_carou_ailias_2.png"
const ana2 =
  "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_h_carou_ana2.png"
const sc =
  "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_carou_sc.png"
const bri_aa =
  "https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_carou_bri_aa_950.png"

const Carousel = (props) => {
  const [carouselVisible, setCarouselVisible] = useState(true)
  const [images] = useState([
    { url: wc, id: 1, class_name: "carousel_slide" },
    { url: ana, id: 2, class_name: "carousel_slide" },
    { url: sc, id: 3, class_name: "carousel_slide" },
    { url: ana2, id: 4, class_name: "carousel_slide" },
    { url: ailias, id: 5, class_name: "carousel_slide" },
    { url: bri_aa, id: 6, class_name: "carousel_slide" },
  ])
  const [idx, setIdx] = useState(0)
  const [source, setSource] = useState(images[idx].url)
  const [visible, setVisibility] = useState(false)
  // const [device, setDevice] = useState("")
  // const width = window.innerWidth
  // const [deviceWidth, setDevicewidth] = useState(width)
  const transitions = useTransition(images[idx], (item) => item.id, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { tension: 220, friction: 120 },
  })
  const slide = transitions.map(({ item, props, key }) => {
    return (
      // <ReactImageMagnify
      //   {...{
      //     smallImage: {
      //       alt: "dw",
      //       src: `${item.url}`,
      //       isFluidWidth: true,
      //     },
      //     largeImage: {
      //       src: `${item.url}`,
      //       width: 1800,
      //       height: 1989,
      //     },
      //     // imageClassName: props.portraitClass,
      //     enlargedImagePosition: "over",
      //   }}
      // >
      <animated.img
        className={item.class_name}
        style={props}
        key={key}
        src={`${item.url}`}
        alt=""
      />
      // </ReactImageMagnify>
    )
  })

  useEffect(() => {
    setSource(images[idx].url)
  }, [idx, images])

  useEffect(() => {
    let interval = setInterval(() => {
      setIdx((index) => (index + 1) % images.length)
    }, 7000)
    return () => {
      clearInterval(interval)
    }
  })

  //------ auto-slide for small screen and mobile devices -------
  // useEffect(() => {
  //   const handleResize = () => {
  //     setDevicewidth(window.innerWidth);
  //     //   console.log(deviceWidth, device);
  //   };
  //   window.addEventListener("resize", handleResize);
  //   let interval;
  //   if (deviceWidth <= 815) {
  //     setDevice("mobile");
  //   } else {
  //     setDevice("desktop");
  //   }
  //   if (device === "mobile") {
  //     interval = setInterval(() => {
  //       setIdx((index) => (index + 1) % images.length);
  //     }, 2500);
  //   }
  //   return () => {
  //     clearInterval(interval);
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, [device, deviceWidth, images.length]);

  const handleKeyEvent = useCallback((e) => {
    switch (e.keyCode) {
      case 37:
        showPrevSlide()
        break
      case 39:
        showNextSlide()
        break
      default:
        return
    }
  })

  useEffect(() => {
    window.addEventListener("keydown", handleKeyEvent)
    return () => {
      window.removeEventListener("keydown", handleKeyEvent)
    }
  }, [handleKeyEvent])

  useEffect(() => {})

  const showArrows = () => {
    setVisibility(true)
  }

  const hideArrows = () => {
    setVisibility(false)
  }

  const showPrevSlide = () => {
    if (idx === 0) {
      setIdx(images.length - 1)
    } else {
      setIdx(idx - 1)
    }
    setSource(images[idx])
  }

  const showNextSlide = () => {
    if (idx === images.length - 1) {
      setIdx(0)
    } else {
      setIdx(idx + 1)
    }
    setSource(images[idx])
  }

  // const showUnanimatedCarousel = () => {
  //   if (idx === 0) {
  //     setCarouselVisible(false)
  //   }
  //   setTimeout(() => {
  //     setCarouselVisible(true)
  //     showNextSlide()
  //   }, 3000)
  // }
  // const hideUnanimatedCarousel = () => {
  //   setCarouselVisible(false)
  // }
  return (
    <div
      className={
        carouselVisible ? "carousel_container" : "carousel_container hidden"
      }
    >
      <div
        className={"carousel"}
        onMouseEnter={showArrows}
        onMouseLeave={hideArrows}
      >
        <div
          className="slide_container"
          // onMouseEnter={showUnanimatedCarousel}
          // onMouseLeave={hideUnanimatedCarousel}
        >
          {slide}
          <div
            className={
              visible
                ? "slider_buttons_container visible_container"
                : "slider_buttons_container hidden_container"
            }
          >
            <div className="slider_button prev" onClick={showPrevSlide}>
              <span className="chevron left"></span>
            </div>
            <div className="slider_button next" onClick={showNextSlide}>
              <span className="chevron right"></span>
            </div>
          </div>
        </div>
      </div>
      <div className="slider_buttons_container-sm_screen">
        <div className="slider_button prev" onClick={showPrevSlide}>
          <span className="chevron left"></span>
        </div>
        <div className="slider_button prev" onClick={showNextSlide}>
          <span className="chevron right"></span>
        </div>
      </div>
    </div>
  )
}

export default Carousel
