import React, { Component, useState, useEffect } from "react"
import Diptych from "../../pages/artwork/portrait/diptych/humanoids/Diptych"

import "./Humanoids.css"

const Humanoids = () => {
  const [loading, setLoading] = useState(false)
  const [portraitWidth, setPortraitWidth] = useState(600)
  const [portraitHeight, setPortraitHeight] = useState(663)
  const [magnifiedWidth, setMagnifiedWidth] = useState(1800)
  const [magnifiedHeight, setMagnifiedHeight] = useState(1989)

  useEffect(() => {
    document.title = "Daniel Wallace | Humanoids"
    let timeout = setTimeout(() => {
      setLoading({ loading: true })
    }, 200)

    return () => {
      clearTimeout(timeout)
    }
  }, [])
  return (
    <div className={loading ? "humanoids_page fading-in" : "humanoids_page"}>
      <div className="humanoids_intro_container">
        <div className="humanoids_title_container">
          <div className="humanoids_title">
            <p className="humanoids">humanoids</p>
          </div>
        </div>
        <div className="exh_m">
          <img
            src="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_exh_01.jpg"
            alt=""
          />
        </div>
      </div>
      <div className="humanoids_description">
        <div className="humanoids_desc-content">
          <p>
            These portraits are from Wallace's series “Humanoids”, which Wallace
            captured at his studio. They employ physical world capture methods
            (including photography and motion capture), and traditional digital
            painting and digital sculpting techniques, bridging surrealism,
            photorealism, CGI, and hyperrealism.
          </p>
        </div>
      </div>
      <div className="humanoids_zoom_instructions_wrapper">
        <div className="humanoids_zoom_instructions desktop">
          mouse over portraits to see details
        </div>
        <div className="humanoids_zoom_instructions mobile">
          press and hold over portraits to see details
        </div>
      </div>
      <div className="diptych_grp">
        <Diptych
          collection="humanoids"
          smallPortraitSrcLeft="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_eva4_f.jpg"
          largePortraitSrcLeft="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_eva4_f_3800.jpg"
          smallPortraitSrcRight="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_eva4_b.jpg"
          largePortraitSrcRight="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_eva4_b_3800.jpg"
          portraitWidth={portraitWidth}
          portraitHeight={portraitHeight}
          magnifiedWidth={magnifiedWidth}
          magnifiedHeight={magnifiedHeight}
        />
        <Diptych
          collection="humanoids"
          smallPortraitSrcLeft="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_eva3_f.jpg"
          largePortraitSrcLeft="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_eva3_f_3800.jpg"
          smallPortraitSrcRight="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_eva3_b.jpg"
          largePortraitSrcRight="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_eva3_b_3800.jpg"
          portraitWidth={portraitWidth}
          portraitHeight={portraitHeight}
          magnifiedWidth={magnifiedWidth}
          magnifiedHeight={magnifiedHeight}
        />
      </div>
      <div className="humanoids_caption_container">
        <div className="humanoids_caption">
          <p>
            An ongoing series, "Humanoids" is a meditation on future
            consumerism, human loneliness and our need for companionship,
            sexuality in the age of 'On Demand' culture, virtualized
            consumption, the dark side of techno-utopianism (i.e., the '21st
            Century American dream'), and the mass public faith in technology as
            a solution to problems for which technology does not have answers.
          </p>
        </div>
      </div>
      <div className="diptych_grp">
        <Diptych
          smallPortraitSrcLeft="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_eva1_f.jpg"
          largePortraitSrcLeft="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_eva1_f_3800.jpg"
          smallPortraitSrcRight="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_eva1_b.jpg"
          largePortraitSrcRight="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_eva1_b_3800.jpg"
          portraitWidth={portraitWidth}
          portraitHeight={portraitHeight}
          magnifiedWidth={magnifiedWidth}
          magnifiedHeight={magnifiedHeight}
        />
        <Diptych
          collection="humanoids"
          smallPortraitSrcLeft="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_an3_f.jpg"
          largePortraitSrcLeft="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_an3_f_3800.jpg"
          smallPortraitSrcRight="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_an3_b.jpg"
          largePortraitSrcRight="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_an3_b_3800.jpg"
          portraitWidth={portraitWidth}
          portraitHeight={portraitHeight}
          magnifiedWidth={magnifiedWidth}
          magnifiedHeight={magnifiedHeight}
        />
        <Diptych
          collection="humanoids"
          smallPortraitSrcLeft="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_an_pl_f.jpg"
          largePortraitSrcLeft="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_an_pl_f_3800.jpg"
          smallPortraitSrcRight="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_an_pl_b.jpg"
          largePortraitSrcRight="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_an_pl_b_3800.jpg"
          portraitWidth={portraitWidth}
          portraitHeight={portraitHeight}
          magnifiedWidth={magnifiedWidth}
          magnifiedHeight={magnifiedHeight}
        />
        <Diptych
          smallPortraitSrcLeft="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_an_f.jpg"
          largePortraitSrcLeft="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_an_f_3800.jpg"
          smallPortraitSrcRight="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_an_b.jpg"
          largePortraitSrcRight="https://daniel-nadler-images.s3.us-east-2.amazonaws.com/dw_an_b_3800.jpg"
          portraitWidth={portraitWidth}
          portraitHeight={portraitHeight}
          magnifiedWidth={magnifiedWidth}
          magnifiedHeight={magnifiedHeight}
        />
      </div>
    </div>
  )
}

export default Humanoids
